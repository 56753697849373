import { useRef, useState } from 'react';

export const useHover = () => {
  const [isHovered, setIsHovered] = useState(false);
  const nodeRef = useRef<HTMLDivElement>(null);
  const [timeoutState, setTimeoutState] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timeoutState) {
      clearTimeout(timeoutState);
    }

    const timeout = setTimeout(() => {
      setIsHovered(true);
    }, 500);

    setTimeoutState(timeout);
  };

  const handleMouseLeave = () => {
    if (timeoutState) {
      clearTimeout(timeoutState);
    }

    const timeout = setTimeout(() => {
      setIsHovered(false);
    }, 500);

    setTimeoutState(timeout);
  };

  return { isHovered, handleMouseEnter, handleMouseLeave, nodeRef };
};
