import React from 'react';
import { IGetBackendTask } from '../../../../../../../types/entities/task';
import Icon from '../../../../../../ui/icon/Icon';
import { useTranslation } from 'react-i18next';
import { convertDateBackToFrontTimestamp } from '../../../../../../../utils/convertDates';
import { fifth_color } from '../../../../../../../styles/colors';
import Dot from '../../../../../../ui/dot/Dot';
import Label from '../../../../../../ui/label/Label';
import Button from '../../../../../../ui/button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../../constants/routes';

type Props = {
  task: IGetBackendTask;
  handleOnClick: (task: IGetBackendTask) => void;
};
const TaskContent = ({ task, handleOnClick }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'tasks' });
  const navigate = useNavigate();

  const handleOnClickTask = () => {
    handleOnClick(task);
    navigate(ROUTES.TODOS);
  };

  return (
    <div className='notification-content flex gap-2 pointer' onClick={handleOnClickTask}>
      <Icon icon='tasks_2' color='blocked' size='large' />
      <div className='flex-col gap-2' style={{ width: '100%', boxSizing: 'border-box' }}>
        <div className='flex justify-between'>
          <span
            className={`title font-14 semibold-font ${
              task.completed ? 'disabled-text-color' : ''
            }`}>
            {t('taskNotificationTitle')}
          </span>
          <span className='font-12 regular-font disabled-text-color flex gap-2 items-center'>
            {convertDateBackToFrontTimestamp(task.created_at)}
            {!task.completed && <Dot type='custom' color={fifth_color} />}
          </span>
        </div>
        <span className={`font-14 regular-font ${task.completed ? 'disabled-text-color' : ''}`}>
          {task.description}
        </span>
        {/* <div className='flex gap-2'>
          <Icon icon='file' color='gray' />
          <span className='font-12 regular-font disabled-text-color'>
            {notification.message.params.notification.file_name}
          </span>
        </div> */}
        <div
          className='tags'
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '0.5rem' }}>
          {task.tags.map((elem) => (
            <Label lookAndFeel='secondary' key={elem}>
              {elem}
            </Label>
          ))}
        </div>
        <div className='flex justify-between gap-2 items-center'>
          <Button
            lookAndFeel='secondary'
            text={t('goToTasks')}
            size='small'
            style={{ width: 'fit-content' }}
          />
          <span className='font-12 regular-font disabled-text-color'>
            {t(`categories.${task.category}`)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TaskContent;
