import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../ui/tooltip/Tooltip';
import './styles.scss';

type Props = {
  total?: number;
  totalCompleted?: number;
};
function TaskProgress({ total = 0, totalCompleted = 0 }: Props) {
  const { t } = useTranslation();

  return (
    <div className='task-progress'>
      <div className='task-progress__content'>
        <div className='flex' style={{ alignItems: 'center', gap: '0.5rem' }}>
          <span className='highlight-text-color pointer co2-dashboard-font-small'>
            {totalCompleted}/{total}
          </span>
          <span className='body3-font disabled-text-color'>{t('header.tasksCompleted')}</span>
          <Tooltip text={t('header.taskProgressTooltip')} />
        </div>
        <span className='body3-font disabled-text-color'>
          {total - totalCompleted} {t('header.pending')}
        </span>
      </div>
      <progress
        id='task_progress'
        value={String(totalCompleted)}
        max={String(total)}
        className='progress'>
        {total > 0 ? totalCompleted / total : 0}
      </progress>
    </div>
  );
}

export default TaskProgress;
