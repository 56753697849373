import React, { useRef, useState } from 'react';
import TooltipChart from '../../tooltip/TooltipChart';
import numberToDecimal from '../../../../utils/numberToDecimal';
import formatNumber from '../../../../utils/formatNumber';

type Props = {
  value: number;
  color: string;
  totalValue: number;
  index: number;
  totalLength: number;
  percentageValue: number;
};
const Bar = ({ value, color, totalValue, index, totalLength, percentageValue }: Props) => {
  const percentageWidth = numberToDecimal((100 * value) / totalValue);

  const ref = useRef<HTMLDivElement>(null);

  // Show tooltip on hover
  const [showTooltip, setShowTooltip] = useState(false);

  const [tooltipInfo, setTooltipInfo] = useState({
    top: 0,
    left: 0,
    text: ''
  });

  const handleShowTooltip = () => {
    // if (value === 0) return;
    // Get where to show tooltip from the bar knowing where the cursor is hovering
    const { top, left, width } = ref.current!.getBoundingClientRect();
    const tooltipTop = top - 35;
    const tooltipLeft = left + width / 2;
    const tooltipText = `${numberToDecimal(percentageValue)}%`;

    setTooltipInfo({
      top: tooltipTop,
      left: tooltipLeft,
      text: tooltipText
    });
    setShowTooltip(true);
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  const { top, left, text } = tooltipInfo;

  if (value === 0) {
    return null;
  }

  let marginRight = '-8px';
  const marginLeft = '0';

  if (index === totalLength - 1) {
    marginRight = '0';
  }
  // if (totalLength < 3 && index === 0) {
  //   marginLeft = '8px';
  // }

  return (
    <>
      <div
        className='horizontal-bar-wrapper'
        style={{
          width: value > 0 ? `${percentageWidth}%` : '16px',
          zIndex: totalLength - index,
          minWidth: value > 0 ? '45px' : '16px',
          alignSelf: value > 0 ? 'flex-start' : 'flex-end',
          marginRight,
          marginLeft
        }}>
        {value > 0 && (
          <span className='body1-font value-wrapper'>{formatNumber(numberToDecimal(value))}</span>
        )}
        <div
          className='bar'
          style={{
            backgroundColor: color
          }}
          onMouseEnter={handleShowTooltip}
          onMouseLeave={handleHideTooltip}
          ref={ref}
        />
      </div>
      {showTooltip && <TooltipChart text={text} top={top} left={left} />}
    </>
  );
};

export default Bar;
