import apiFetch from '../../../../utils/apiFetch';
import { IFilter, generateQueryParamsFromObject, getUrl } from '../../../../utils/url';
import { PAGE_SIZE } from '../constants';
import { LCAPortfolioResponse } from '../types/LCAPortfolio';

const OLD_URL = '/acv/portfolio';
const URL = '/lca/portfolio';

/* const MOCK_DATA: LCAPortfolioResponse[] = Array.from({ length: 10 }).map((_, page) => ({
  page: page + 1,
  total: PAGE_SIZE * 10,
  items: Array.from({ length: PAGE_SIZE }).map((_, index) => ({
    id: (index + 1 + page * PAGE_SIZE).toString(),
    name: `Item ${index + 1 + page * PAGE_SIZE}`,
    description: `Description ${index + 1 + page * PAGE_SIZE}`
  }))
})); */

export const getPortfolio = async (
  page: number,
  filters: IFilter[],
  old_flow?: boolean
): Promise<LCAPortfolioResponse> => {
  if (old_flow && page === 1) {
    const response1 = await apiFetch(
      'GET',
      getUrl(OLD_URL, {
        queryParams: generateQueryParamsFromObject({
          page,
          size: PAGE_SIZE
        }),
        filters
      })
    );

    const data = response1.data;

    const response2 = await apiFetch(
      'GET',
      getUrl(URL, {
        queryParams: generateQueryParamsFromObject({
          page,
          size: PAGE_SIZE
        }),
        filters
      })
    );

    const data2 = response2.data;

    return {
      page: data2.page,
      total: data2.total + data.total,
      items: [...data.items, ...data2.items]
    };
  }

  const response = await apiFetch(
    'GET',
    getUrl(URL, {
      queryParams: generateQueryParamsFromObject({
        page,
        size: PAGE_SIZE
      }),
      filters
    })
  );

  return response.data;
};
