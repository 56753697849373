import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import { validate } from 'email-validator';
import { ROUTES } from '../../constants/routes';
import Button from '../ui/button/Button';
import './styles.scss';

const EmailSent = () => {
  const { t } = useTranslation();
  const params = useParams();

  const { email } = params;

  if (!email || !validate(email)) {
    return <Navigate replace to={ROUTES.ONBOARDING} />;
  }

  const subtitle = (
    <>
      <span>{t('emailSent.subtitle1')}</span>
      <span className='email highlight-text-color'>{email}</span>
      <span>{t('emailSent.subtitle2')}</span>
    </>
  );

  return (
    <section className='email-sent'>
      <div className='email-sent-section'>
        <img className='email-sent-section__logo' src='/images/logoBlack.svg' alt='logo' />
        <div className='email-sent-section__header'>
          <h1 className='headline1-font on-light-text-color'>{t('emailSent.title')}</h1>
          <h3 className='subtitle1-font on-light-text-color'>{subtitle}</h3>
        </div>
        <div className='email-sent-section__footer'>
          <Button
            lookAndFeel='primary'
            text={t('emailSent.resendMail')}
            onClick={() => {
              // send mail
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default EmailSent;
