import Table from 'rc-table';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ReactNode } from 'react';
import { Column } from '../../../types/table';
import formatNumber from '../../../utils/formatNumber';
import LoaderTables from '../loaders/loaderTables/LoaderTables';
import './styles.scss';

interface Props extends React.ComponentProps<typeof Table> {
  data: any[];
  columns: Column[];
  loading: boolean;
  size?: string;
  emptyText?: ReactNode;
  loadingFooter?: boolean;
}
function TableComponent({
  data,
  columns,
  loading,
  size = 'medium',
  emptyText,
  loadingFooter,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const onRenderRow = (record: any) => {
    if (record.status === 'blocked') {
      return {
        className: 'disabled'
      };
    }
    return {};
  };

  const renderLoading = () => {
    if (loadingFooter) {
      return (
        <div className='spinner-list'>
          <LoaderTables mode='list' />
        </div>
      );
    }
    if (rest.footer) return rest.footer;
  };
  // Check every elem of data and if the value is a number, format it
  const dataFormatted = data?.map((elem) => {
    const elemFormatted = { ...elem };
    for (const key in elemFormatted) {
      if (typeof elemFormatted[key] === 'number') {
        elemFormatted[key] = formatNumber(elemFormatted[key]);
      }
    }
    return elemFormatted;
  });
  const dataMapped = dataFormatted?.map((elem, index) => ({
    ...elem,
    key: elem.key ? elem.key : elem?.id ?? `elem-${index}`
  }));

  return (
    <div className={`table-wrapper ${size}`}>
      {loading ? (
        <div className='loading-skeleton-wrapper'>
          <Skeleton className='loading-skeleton' count={5} />
        </div>
      ) : (
        <Table
          columns={columns}
          data={dataMapped}
          onRow={onRenderRow}
          indentSize={5}
          emptyText={emptyText ?? t('table.empty')}
          footer={renderLoading}
          expandable={{
            defaultExpandAllRows: false
          }}
          className={`table-columns-${columns.length}`}
          {...rest}
        />
      )}
    </div>
  );
}

export default TableComponent;
