import { useTranslation } from 'react-i18next';
import { EsgFrameworkCompleteness } from '../../../../../types/entities/esgMetrics';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import Card from '../../../dashboardCommon/card/Card';
import FrameworkProgressBar from './components/FrameworkProgressBar';

type Props = {
  data: EsgFrameworkCompleteness[];
  loading: boolean;
};
const FrameworkProgress = ({ data, loading }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });

  if (loading) {
    return <LoaderCard />;
  }

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <div
          className='on-card-gray-bg-color pt-4 pb-4 pr-6 pl-6 flex flex-col gap-4 rounded-8'
          style={{ height: '8.5rem', overflowY: 'auto' }}>
          {data.map((elem) => (
            <FrameworkProgressBar
              key={elem.framework}
              title={elem.framework.replaceAll('_', ' ')}
              total={elem.total}
              completed={elem.completed}
              uncertainty={0}
            />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default FrameworkProgress;
