import { useTranslation } from 'react-i18next';
import { Column } from '../../../../../../types/table';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import { IPurchaseSupplierFrontend } from '../../../../../../types/purchaseSupplier';
import { Status } from '../../../../../../types/utilsEnums/status';
import { PurchasesStatusTag } from '../../components/statusTag/StatusTag';
import { PurchaseStatus } from '../../type';

export const useColumns = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'purchases.suppliersTable'
  });

  const columns: Column<IPurchaseSupplierFrontend>[] = [
    {
      title: t('columns.supplier'),
      key: 'name',
      dataIndex: 'name',
      width: 800
    },
    {
      title: t('columns.country'),
      key: 'country',
      dataIndex: 'country',
      width: 100
    },
    {
      title: t('columns.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status: PurchaseStatus) => {
        if (status === 'active') {
          return <PurchasesStatusTag status={Status.ACTIVE} text={t(`tags.${status}`)} />;
        }

        return (
          <>
            <TooltipWrapper text={t(`tooltip.${status}`)} position='top'>
              <PurchasesStatusTag status={status} text={t(`tags.${status}`)} />
            </TooltipWrapper>
          </>
        );
      },
      width: 150
    },
    {
      title: t('columns.purchasesCount'),
      key: 'purchasesCount',
      dataIndex: 'purchasesCount',
      width: 200
    },
    {
      title: t('columns.co2e'),
      key: 'co2e',
      dataIndex: 'co2e',
      width: 150,
      render: (co2e, row) => {
        if (row.status !== Status.ACTIVE) {
          return '-';
        }

        return <span className='font-body-b2-sb text-gradient'>{co2e ?? 0} kg</span>;
      }
    }
  ];

  return { columns };
};
