import { useTranslation } from 'react-i18next';

const useToggleOptions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.metrics.toggleOptions'
  });
  const toggleOptions = [
    {
      id: 'environmental',
      name: t('environmental')
    },
    {
      id: 'social',
      name: t('social')
    },
    {
      id: 'governance',
      name: t('governance')
    },
    {
      id: 'personalized',
      name: t('personalized')
    }
  ];
  return toggleOptions;
};

export default useToggleOptions;
