import { Handle, Position } from 'reactflow';
import { LCACard } from '../../../Portfolio/LCADetail/components/LCACard';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import { ScenarioNode } from '../../types/LCAScenarios';
import { NodeImpact } from '../../../../../services/api/lca/lcaPortfolio';
import { useNodeLCAImpact } from '../../hooks/useNodeLCAImpact';
import { buildLegendUnitFromNodeImpact } from '../../../utils';

type PieceNodeProps = {
  data: ScenarioNode['data'];
  disableHover?: boolean;
  nodeImpact?: NodeImpact;
};

const NodeAppearance: React.FC<PieceNodeProps> = ({ data, disableHover, nodeImpact }) => {
  const { t } = useLCATranslation();

  return (
    <LCACard.Layout disableHover={disableHover}>
      <div className='flex-col gap-y-4'>
        <LCACard.Content>
          <LCACard.Icon icon={NODE_ICONS[NODE_TYPES.piece]} lookAndFeel='piece' />
          <div className='flex-col'>
            <span className='font-body-b2-r text-gray-light'>{t('lcaScenarios.piece')}</span>
            <span className='font-body-b1-sb'>{data.label}</span>
          </div>
          {nodeImpact && (
            <LCACard.TotalImpact
              impact={nodeImpact.sum_total_impact.toFixed(2)}
              unit={buildLegendUnitFromNodeImpact(nodeImpact)}
              position='right'
            />
          )}
        </LCACard.Content>
        {Boolean(data.tags?.length) && (
          <div className='flex gap-x-2'>
            {data.tags?.map((tag) => (
              <LCACard.Tag key={data.id + tag} disabled>
                {tag}
              </LCACard.Tag>
            ))}
          </div>
        )}
      </div>
    </LCACard.Layout>
  );
};

export const PieceNode = ({ data }: PieceNodeProps) => {
  const process = data.inputs.find((input) => input.type === NODE_TYPES.process);

  const { data: nodeImpact } = useNodeLCAImpact({ node_id: process?.id });

  return (
    <>
      <Handle
        type='target'
        position={Position.Left}
        isConnectableStart={false}
        isConnectableEnd={data.inputs.length === 0}
      />
      <NodeAppearance data={data} nodeImpact={nodeImpact} />
      <Handle type='source' position={Position.Right} isConnectable={false} />
    </>
  );
};

PieceNode.Appearance = NodeAppearance;
