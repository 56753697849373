// I18N
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import englishLiterals from './en.json';
import spanishLiterals from './es.json';
import portugueseLiterals from './pt.json';
import lcaLiteralsSpanish from './lca/es.json';
import lcaLiteralsEnglish from './lca/en.json';
import lcaLiteralsPortuguese from './lca/pt.json';

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true
};

i18next.use(LanguageDetector).init({
  interpolation: {
    // React already does escaping
    escapeValue: false
  },
  fallbackLng: 'en',
  // whitelist: ['en', 'es'],
  debug: true,
  detection: langDetectorOptions,
  // Using simple hardcoded resources for simple example
  resources: {
    es: {
      lca: lcaLiteralsSpanish,
      translation: spanishLiterals
    },
    en: {
      lca: lcaLiteralsEnglish,
      translation: englishLiterals
    },
    pt: {
      lca: lcaLiteralsPortuguese,
      translation: portugueseLiterals
    }
  },
  react: {
    // https://react.i18next.com/latest/trans-component#trans-props
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i', 'span']
  }
});

export default i18next;
