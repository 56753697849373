import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { FilterDatesContext } from '../../../../context/filterDatesContext';
import { getOrganizationESGStatus } from '../../../../services/api/esg';
import { EsgCategoryCompleteness } from '../../../../types/entities/esgMetrics';
import CardEsg from '../components/CardEsg/CardEsg';

const useData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });
  const navigate = useNavigate();
  const { startDate, endDate } = useContext(FilterDatesContext);

  const [organizationESGStatus, setOrganizationESGStatus] = useState<EsgCategoryCompleteness[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    const startDateParsed = moment(startDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
    const endDateParsed = moment(endDate, 'DD/MM/YYYY').format('YYYY/MM/DD');

    setLoading(true);
    const data = await getOrganizationESGStatus(startDateParsed, endDateParsed, [
      {
        field: 'data_source',
        type: 'eq',
        value: 'F'
      }
    ]);
    setOrganizationESGStatus(data);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) return;

    fetchData();
  }, [startDate, endDate]);

  const environmental = organizationESGStatus?.find((item) => item.category === 'environmental');
  const social = organizationESGStatus?.find((item) => item.category === 'social');
  const governance = organizationESGStatus?.find((item) => item.category === 'governance');
  const general_information = organizationESGStatus?.find(
    (item) => item.category === 'general_information'
  );

  const data = [
    <CardEsg
      key='double-materiality'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/double_materiality')}>
      <>
        <CardEsg.Title title={t('doubleMaterialityTitle')} />
        <CardEsg.Description descriptions={[t('doubleMaterialityDescription')]} />
        <CardEsg.Completeness completeness={0} />
        <CardEsg.Esrs esrs={t('startNow')} />
      </>
    </CardEsg>,
    <CardEsg
      key='general-information'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/general_information')}>
      <>
        <CardEsg.Title title={t('generalInformationTitle')} />
        <CardEsg.Subtitle
          mandatoryDatapoints={general_information?.mandatory ?? 0}
          datapoints={general_information?.total ?? 0}
        />
        <CardEsg.Esrs esrs={'ESRS 2'} />
        <CardEsg.Completeness
          completeness={
            general_information && general_information.total > 0
              ? (100 * general_information?.completed) / general_information.total
              : 0
          }
        />
      </>
    </CardEsg>,
    <CardEsg
      key='ambiental'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/environmental')}>
      <>
        <CardEsg.Title title={t('ambientalTitle')} />
        <CardEsg.Subtitle
          mandatoryDatapoints={environmental?.mandatory ?? 0}
          datapoints={environmental?.total ?? 0}
        />
        <CardEsg.Esrs esrs={'ESRS E1 E2 E3 E4 E5'} />
        <CardEsg.Completeness
          completeness={
            environmental && environmental.total > 0
              ? (100 * environmental?.completed) / environmental.total
              : 0
          }
        />
      </>
    </CardEsg>,
    <CardEsg key='social' onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/social')}>
      <>
        <CardEsg.Title title={t('socialTitle')} />
        <CardEsg.Subtitle
          mandatoryDatapoints={social?.mandatory ?? 0}
          datapoints={social?.total ?? 0}
        />
        <CardEsg.Esrs esrs={'ESRS S1 S2 S3 S4'} />
        <CardEsg.Completeness
          completeness={social && social.total > 0 ? (100 * social?.completed) / social.total : 0}
        />
      </>
    </CardEsg>,
    <CardEsg
      key='governance'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/governance')}>
      <>
        <CardEsg.Title title={t('governanceTitle')} />
        <CardEsg.Subtitle
          mandatoryDatapoints={governance?.mandatory ?? 0}
          datapoints={governance?.total ?? 0}
        />
        <CardEsg.Esrs esrs={'ESRS G1'} />
        <CardEsg.Completeness
          completeness={
            governance && governance.total > 0
              ? (100 * governance?.completed) / governance.total
              : 0
          }
        />
      </>
    </CardEsg>,
    <CardEsg key='eu-taxonomy' onClick={() => null}>
      <CardEsg.Tooltip text={t('euTaxonomyTooltip')}>
        <CardEsg.ContentDisabled>
          <CardEsg.Title title={t('euTaxonomy')} />
          <CardEsg.Description descriptions={[t('euTaxonomyDescription')]} />
          <CardEsg.Completeness completeness={0} />
          <CardEsg.Esrs esrs={t('euTaxonomyObjectives')} />
        </CardEsg.ContentDisabled>
      </CardEsg.Tooltip>
    </CardEsg>
  ];

  const onClickCard = (url: string) => navigate(url);

  return { data, loading };
};

export default useData;
