import { Node } from 'reactflow';
import { LCAProcessMetadata } from '../../../../../services/api/lca/lcaPortfolio';

export const checkIssues = (nodeMetadata: LCAProcessMetadata | undefined, data: Node['data']) => {
  const issues = [];
  if (!nodeMetadata?.inputs || nodeMetadata.inputs.length === 0) {
    issues.push('noInputs');
  }

  if (!nodeMetadata?.country) {
    issues.push('noLocation');
  }

  if (!data.inputs.length) {
    issues.push('noRawMaterial');
  }

  return issues;
};

export const checkMetadata = (nodeMetadata: LCAProcessMetadata | undefined) => {
  if (!nodeMetadata) {
    return false;
  }

  return Boolean(nodeMetadata.country);
};

export const generateTags = (nodeMetadata: LCAProcessMetadata | undefined, data: Node['data']) => {
  const tags = [];

  tags.push({
    lookAndFeel: 'primary',
    disabled: !nodeMetadata?.inputs?.filter((input) => input.type !== 'raw_material').length,
    value: 'inputs'
  });

  tags.push({
    lookAndFeel: 'primary',
    disabled: !nodeMetadata?.country,
    value: 'location'
  });

  tags.push({
    lookAndFeel: 'primary',
    disabled: !data.inputs.length,
    value: 'rawMaterial'
  });

  return tags;
};

export const getHandleStyle = (type: 'target' | 'source', hasProperConnection: boolean) => {
  if (type === 'target') {
    return {
      backgroundImage: hasProperConnection
        ? '#EAEAEA'
        : 'linear-gradient(to right, #2945ff, #38cfd9)',
      width: hasProperConnection ? '0.5rem' : '0.75rem',
      height: hasProperConnection ? '0.5rem' : '0.75rem',
      left: hasProperConnection ? '-0.25rem' : '-0.375rem',
      top: '2rem'
    };
  }

  return {
    backgroundImage: hasProperConnection
      ? '#EAEAEA'
      : 'linear-gradient(to right, #2945ff, #38cfd9)',
    width: hasProperConnection ? '0.5rem' : '0.75rem',
    height: hasProperConnection ? '0.5rem' : '0.75rem',
    right: hasProperConnection ? '-0.25rem' : '-0.375rem'
  };
};
