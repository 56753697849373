import apiFetch from '../../utils/apiFetch';
import dateToTimestamp from '../../utils/dateToTimestamp';

export const getTotalImpactsInvestments = async (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  returnRequest = false
) => {
  try {
    const request = apiFetch(
      'get',
      `/matrices/investment_impacts/${organizationId}`,
      null,
      {
        'x-organization-id': organizationId
      },

      {
        start_date: dateToTimestamp(startDate),
        end_date: dateToTimestamp(endDate)
      }
    );
    if (returnRequest) return request;

    const response = await request;
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getTotalImpactsInvestmentsByMonth = async (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  returnRequest = false
) => {
  try {
    const request = apiFetch(
      'get',
      `/matrices/investment_impacts_by_month/${organizationId}`,
      null,
      {
        'x-organization-id': organizationId
      },

      {
        start_date: dateToTimestamp(startDate),
        end_date: dateToTimestamp(endDate)
      }
    );
    if (returnRequest) return request;

    const response = await request;
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getMatrix = async (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  mode: string,
  returnRequest = false
) => {
  try {
    const request = apiFetch(
      'GET',
      `/matrices/${organizationId}`,
      null,
      {
        'x-organization-id': organizationId
      },
      {
        start_date: dateToTimestamp(startDate),
        end_date: dateToTimestamp(endDate),
        switch: mode
      }
    );
    if (returnRequest) return request;

    const response = await request;
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getMatrixChilds = async (organization_id: string) => {
  try {
    const response = await apiFetch('GET', `/matrices/${organization_id}/status`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const linkCompany = async (id: string) => {
  try {
    const response = await apiFetch('POST', '/matrices/link', {
      vat: id
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getMatricesParents = async (page: number, size: number) => {
  try {
    const response = await apiFetch('GET', `/matrices/parents`, null, null, {
      page,
      size
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestAccessFromGroupFund = async (child_id: string) => {
  try {
    const response = await apiFetch('POST', `/matrices/groups_funds/request_access`, {
      child_id
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const deleteMatrixRel = async (id: string, parent_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/matrices/rel/${id}/${parent_id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};
