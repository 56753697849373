import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { patchLCANode } from '../../../../services/api/lca/lcaScenarios';
import { LCABlockBack } from '../../types/LCABlock';

type Params = {
  acv_id: string;
};

export const usePatchNode = ({ acv_id }: Params) => {
  const queryClient = useQueryClient();
  const selectedOrg = useSelectedOrganization();

  const { mutateAsync } = useMutation({
    mutationFn: patchLCANode,
    onSuccess: (data) => {
      queryClient.setQueryData(['lcaBlocks', selectedOrg?.id, acv_id], (prev: LCABlockBack[]) => {
        const index = prev.findIndex((block) => block.id === data.id);

        prev[index] = {
          ...prev[index],
          coordinates_x: data.coordinates_x,
          coordinates_y: data.coordinates_y
        };

        return [...prev];
      });
    }
  });

  return {
    patchNode: mutateAsync
  };
};
