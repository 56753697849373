import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import Button from '../../../../ui/button/Button';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import Modal from '../../../../ui/modal/Modal';
import { useModalContext } from '../ModalHandler';
import { FormType } from './constants';
import { materialFormSchema } from './materialFormSchema';
import { ValidateFn } from './types';

type Props = {
  form: FormType;
  setForm: React.Dispatch<React.SetStateAction<FormType>>;
  errors: ErrorContainer<FormType> | null;
  validate: ValidateFn;
  handleNext: () => void;
};

export const FirstStep = ({ form, setForm, errors, validate, handleNext }: Props) => {
  const { setModalData } = useModalContext();

  const { t } = useLCATranslation();

  const handleChange = (key: keyof typeof form) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const onCancel = () => {
    setModalData(undefined);
  };

  const nextStep = () => {
    const isValid = validate(form, materialFormSchema);

    if (!isValid) {
      return;
    }

    handleNext();
  };

  return (
    <>
      <Modal.Content>
        <div className='w-60'>
          <FormText
            value={form.name}
            onChange={handleChange('name')}
            error={errors?.name}
            iconV2='factory'
            label={t('lcaDetail.processFlow.materialModal.nameInput')}
          />
        </div>
      </Modal.Content>
      <Modal.Buttons>
        <Button
          lookAndFeel='secondary'
          text={t('lcaDetail.processFlow.materialModal.cancel')}
          size='small'
          onClick={onCancel}
        />
        <Button
          lookAndFeel='primary'
          text={t('lcaDetail.processFlow.materialModal.next')}
          size='small'
          onClick={nextStep}
        />
      </Modal.Buttons>
    </>
  );
};
