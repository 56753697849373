import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_INVOICES_PER_REQUEST, MAX_PAYLOAD_SIZE } from '../../../../../../../constants';
import { Facility } from '../../../../../../../types/entities/facility';
import getBase64 from '../../../../../../../utils/getBase64';
import makeRequestsInBatches from '../../../../../../../utils/makeRequestInBatches';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FileItem from '../../../../../../ui/fileItem/FileItem';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputFile from '../../../../../../ui/formComponents/inputFile/InputFile';
import DocumentPDFV2 from '../../../../../../ui/pdf/DocumentPDFV2';
import './styles.scss';
import { uploadWasteWizard } from '../../../../../../../services/api/waste';
import { IWasteInvoiceFront, IWasteInvoiceBack } from '../../../../../../../types/entities/waste';
import { InputNuvoWastes } from './nuvo/InputNuvoWastes';

type Props = {
  facility: Facility;
  handleClose: () => void;
};

const adaptFrontToBack = (invoice: IWasteInvoiceFront): IWasteInvoiceBack => {
  return {
    file_name: invoice.file.name,
    base_64: invoice.fileUrl,
    facility_id: invoice.facilityId,
    percentage: invoice.percentage
  };
};

const UploadWasteInvoices = ({ facility, handleClose }: Props) => {
  const { t } = useTranslation();

  // STATES
  const [loadingButton, setLoadingButton] = useState(false);
  const [invoicesToUpload, setInvoicesToUpload] = useState<IWasteInvoiceFront[]>([]);
  const [documentIndex, setDocumentIndex] = useState(0);
  const [sizesBiggerThanMax, setSizesBiggerThanMax] = useState<{ size: number; index: number }[]>(
    []
  );
  const [errors, setErrors] = useState<ErrorType[]>([]);

  const handleUploadFiles = (filesUploaded: File[]) => {
    filesUploaded.forEach((file) => {
      getBase64(file, (file_url: string | null | ArrayBuffer) => {
        if (!file_url) return;
        const newInvoiceToUpload: IWasteInvoiceFront = {
          file: file,
          fileName: file.name,
          fileUrl: file_url as string,
          facilityId: facility.id || '',
          percentage: 1
        };
        setInvoicesToUpload((prev) => [...prev, newInvoiceToUpload]);
      });
    });
  };

  const handleRemoveInvoice = (index: number) => {
    // filter errors to remove the one of type "file"
    const newErrors = errors.filter((elem) => elem.error !== 'file');
    const oldInvoicesToUpload = [...invoicesToUpload];
    oldInvoicesToUpload.splice(index, 1);
    setInvoicesToUpload(oldInvoicesToUpload);
    setErrors(newErrors);
  };

  const handleNextDocument = () => {
    if (invoicesToUpload && documentIndex < invoicesToUpload.length - 1) {
      setDocumentIndex(documentIndex + 1);
    }
  };

  const handlePrevDocument = () => {
    if (invoicesToUpload && documentIndex > 0) {
      setDocumentIndex(documentIndex - 1);
    }
  };

  const handleUploadInvoice = async () => {
    const newErrors = [...errors];
    let sizeBase64 = 0;

    const sizes: number[] = [];
    invoicesToUpload.forEach((invoice) => {
      sizeBase64 = invoice.fileUrl.length / (1024 * 1024);
      sizes.push(sizeBase64);
    });

    const sizesBiggerThanMaxList: {
      size: number;
      index: number;
    }[] = [];
    sizes.forEach((elem, index) => {
      if (elem >= MAX_PAYLOAD_SIZE) {
        sizesBiggerThanMaxList.push({
          size: elem,
          index
        });
      }
    });

    setSizesBiggerThanMax(sizesBiggerThanMaxList);

    if (!invoicesToUpload || invoicesToUpload.length === 0 || sizesBiggerThanMax.length > 0) {
      newErrors.push({
        error: 'file',
        description: t('error.maxPayloadSize')
      });
    }
    if (invoicesToUpload.length > MAX_INVOICES_PER_REQUEST) {
      newErrors.push({
        error: 'file',
        description: t('error.maxInvoicesPerRequest')
      });
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoadingButton(true);

    const uploadRequests: (() => void)[] = [];

    invoicesToUpload.forEach((invoice) => {
      const backInvoice = adaptFrontToBack(invoice);
      uploadRequests.push(() => uploadWasteWizard(backInvoice));
    });

    try {
      const responses = await makeRequestsInBatches(uploadRequests);
      responses.forEach((response: any, index: number) => {
        if (
          response &&
          response.response &&
          response.response.status &&
          response.response.status === 413
        ) {
          sizesBiggerThanMaxList.push({
            size: sizes[index],
            index
          });
          // continue loop to next iteration
          return;
        }
      });
      if (sizesBiggerThanMaxList.length > 0) {
        setSizesBiggerThanMax(sizesBiggerThanMaxList);
        setErrors([{ error: 'file', description: t('error.maxPayloadSize') }]);
        setLoadingButton(false);
        return;
      }
      setLoadingButton(false);
      handleClose();
    } catch (err: any) {
      let responseStatus = 200;
      if (err.response && err.response.status) {
        responseStatus = err.response.status;
      }
      if (responseStatus === 413) {
        setErrors([{ error: 'file', description: t('error.maxPayloadSize') }]);
      } else {
        setErrors([{ error: 'request', description: t('error.uploadInvoices') }]);
      }
      setLoadingButton(false);
    }
  };

  const documentPDF =
    invoicesToUpload && invoicesToUpload.length > 0 ? (
      <DocumentPDFV2 file={invoicesToUpload[documentIndex].fileUrl} />
    ) : null;

  return (
    <div className='flex'>
      {documentPDF && (
        <div className='invoice-pdf-wrapper' style={{ height: '75vh' }}>
          {documentPDF}
          {invoicesToUpload && invoicesToUpload.length > 0 && (
            <div className='change-document-wrapper'>
              <img
                src='/images/icons/chevron.svg'
                className='chevron-icon-left'
                onClick={handlePrevDocument}
                alt='chevron'
              />
              <span className='subtitle3-font'>{`${t('facilityDetail.invoice')} ${
                documentIndex + 1
              }/${invoicesToUpload.length}`}</span>
              <img
                src='/images/icons/chevron.svg'
                className='chevron-icon-right'
                onClick={handleNextDocument}
                alt='chevron'
              />
            </div>
          )}
        </div>
      )}
      <div className='upload-invoice'>
        <FormWrapper>
          <div className='flex-col gap-2' style={{ width: '100%' }}>
            <span className='body1-bold-font'>
              {invoicesToUpload && invoicesToUpload.length > 0
                ? t('facilityDetail.invoiceUploaded')
                : t('facilityDetail.uploadYourInvoices')}
            </span>
            {invoicesToUpload && invoicesToUpload.length > 0 && (
              <div className='files-wrapper'>
                {invoicesToUpload.map((elem: IWasteInvoiceFront, index: number) => (
                  <FileItem
                    key={index}
                    file={elem.file}
                    index={index}
                    icon={'/images/icons/waste.svg'}
                    removeFile={handleRemoveInvoice}
                    error={
                      sizesBiggerThanMax.find((elem) => elem.index === index)
                        ? t('error.fileTooBig')
                        : ''
                    }
                  />
                ))}
              </div>
            )}
            <div className='file-upload-wrapper' style={{ width: '100%' }}>
              <InputFile
                handleFiles={handleUploadFiles}
                buttonText={
                  invoicesToUpload && invoicesToUpload.length > 0
                    ? t('facilityDetail.uploadAnotherInvoice')
                    : t('facilityDetail.uploadInvoiceButtonFile')
                }
                fileNames={
                  invoicesToUpload && invoicesToUpload.length > 0
                    ? invoicesToUpload.map((invoice) => invoice.file.name)
                    : []
                }
                labelText={t('facilityDetail.dragAndDropFile')}
                allowedTypes={['application/pdf']}
                error={errors.find((error) => error.error === 'file')}
              />
            </div>
          </div>
        </FormWrapper>

        <FormButtonSection
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Button
            size='small'
            style={{ width: '195px' }}
            lookAndFeel='primary'
            text={t('facilityDetail.uploadInvoiceButton')}
            onClick={handleUploadInvoice}
            loading={loadingButton}
            disabled={!!errors.find((error) => error.error === 'percentages')}
          />
          <InputNuvoWastes handleClose={handleClose} />
        </FormButtonSection>
        {errors.find((error) => error.error === 'request') && (
          <ErrorText>{errors.find((error) => error.error === 'request')?.description}</ErrorText>
        )}
      </div>
    </div>
  );
};

export default UploadWasteInvoices;
