import { useContext, useEffect, useState } from 'react';
import { FilterDatesContext } from '../../../../context/filterDatesContext';
import {
  getDatasourceProgress,
  getOrganizationFrameworkStatus
} from '../../../../services/api/esg';
import moment from 'moment';
import {
  EsgCategoryCompleteness,
  EsgFrameworkCompleteness
} from '../../../../types/entities/esgMetrics';
import makeRequestsInBatches from '../../../../utils/makeRequestInBatches';

const useGetData = () => {
  const { startDate, endDate } = useContext(FilterDatesContext);

  const [organizationESGStatus, setOrganizationESGStatus] = useState<{
    data: EsgCategoryCompleteness[];
    loading: boolean;
  }>({
    data: [],
    loading: false
  });
  const [organizationFrameworkStatus, setOrganizationFrameworkStatus] = useState<{
    data: EsgFrameworkCompleteness[];
    loading: boolean;
  }>({ data: [], loading: false });

  const fetchData = async () => {
    const startDateParsed = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const endDateParsed = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

    const requests = [];
    if (!organizationESGStatus.loading) {
      setOrganizationESGStatus({ data: [], loading: true });
      requests.push(() => getDatasourceProgress(startDateParsed, endDateParsed, true));
    }

    if (!organizationFrameworkStatus.loading) {
      setOrganizationFrameworkStatus({ data: [], loading: true });
      requests.push(() => getOrganizationFrameworkStatus(startDateParsed, endDateParsed, true));
    }

    const [responseDataSourceProgress, responseFrameworkProgress] = await makeRequestsInBatches(
      requests
    );

    setOrganizationESGStatus({ data: responseDataSourceProgress.data ?? [], loading: false });
    setOrganizationFrameworkStatus({ data: responseFrameworkProgress.data ?? [], loading: false });
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  return {
    organizationESGStatus,
    organizationFrameworkStatus
  };
};

export default useGetData;
