import { InputHTMLAttributes, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../../../../customHooks/useOutsideClick';
import { InputSize } from '../../../../../types/utilsEnums/input';
import { limitString } from '../../../../../utils/limitString';
import LoaderStandard from '../../../loaders/loaderStandard/LoaderStandard';
import InputText from '../../inputText/InputText';
import InputWrapperPlain from '../../inputUtils/inputWrapperPlain/InputWrapperPlain';
import Chevron from '../chevron/Chevron';
import DropdownCard from '../dropdownCard/DropdownCard';
import Option from '../optionList/Option';
import OptionList from '../optionList/OptionList';
import '../styles.scss';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'value' | 'onChange'> {
  placeholder?: string;
  options: SelectOptionFormat[];
  value: SelectOptionFormat;
  searchValue?: string;
  onChange: (value: SelectOptionFormat) => void;
  onChangeSearchValue?: (value: string) => void;
  disabled?: boolean;
  loading?: boolean;
  size?: InputSize;
  extraOptions?: ReactNode;
  avoidOffScreen?: boolean;
  handleGetDown?: () => Promise<void>;
}

const SelectRaw = ({
  placeholder,
  value,
  searchValue,
  options,
  onChange,
  onChangeSearchValue,
  size = InputSize.MEDIUM,
  disabled = false,
  loading = false,
  extraOptions,
  avoidOffScreen,
  handleGetDown,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);

  const wrapperRef = useOutsideClick(() => {
    setShowDropdown(false);
  });

  const handleChangeValue = (value: SelectOptionFormat) => {
    onChange(value);
    setShowDropdown(false);
  };

  if (!options || options.length === 0) placeholder = t('input.noData');
  if ((!options || options.length === 0) && !!searchValue)
    placeholder = t('input.noDataSearch', { search: limitString(searchValue, 10, true) });
  if (loading) placeholder = t('input.loading');

  const showFilter = () => {
    if (searchValue === undefined && options.length <= 4) return false;
    return true;
  };

  const handleShowDropdown = () => {
    if (disabled || loading) return;
    if (options.length <= 0 && !searchValue) return;
    setShowDropdown(true);
  };

  return (
    <div ref={wrapperRef} style={{ width: '100%' }}>
      {/* wrapperRef is used to close the dropdown when clicking outside of it */}
      <div className={`input-select on-light-text-color`} onClick={() => handleShowDropdown()}>
        <InputText
          placeholder={placeholder || t('input.selectOption')}
          value={value?.name}
          className='pointer'
          style={{ caretColor: 'transparent' }}
          disabled={disabled}
          {...props}
        />
        <Chevron rotate={showDropdown} size={size} />
      </div>
      {showDropdown && (
        <DropdownCard wrapperRef={wrapperRef} avoidOffScreen={avoidOffScreen}>
          {showFilter() && (
            <InputWrapperPlain size={size}>
              <InputText
                placeholder={t('search.search')}
                value={searchValue}
                onChange={(e) => onChangeSearchValue && onChangeSearchValue(e?.target?.value || '')}
                autoFocus
              />
            </InputWrapperPlain>
          )}

          <OptionList
            options={options}
            idSelected={value.id}
            handleChangeValue={handleChangeValue}
            handleGetDown={handleGetDown}
            size={size}
          />

          {options.length === 0 && !loading && (
            <div className='select-options-list'>
              <Option
                value={{
                  id: 'noData',
                  name: t('input.noData')
                }}
                size={size}
              />
            </div>
          )}

          {extraOptions}
        </DropdownCard>
      )}
    </div>
  );
};

export default SelectRaw;
