import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../constants/routes';
import { UserContext } from '../../../../context/userContext';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { Task } from '../../../../types/entities/task';
import Button from '../../../ui/button/Button';
import NotificationBubble from '../../../ui/notificationBubbleWrapper/NotificationBubbleWrapper';
import TasksModal from './TasksModal';
import './styles.scss';
import Icon from '../../../ui/icon/Icon';
const TasksButton = () => {
  const { t } = useTranslation();
  const selectedOrganization = useSelectedOrganization();
  const user = useContext(UserContext);

  const [tasks, setTasks] = useState<Task[] | null>(null);
  const [total, setTotal] = useState(0);
  const [totalCompleted, setTotalCompleted] = useState(0);

  const [showTasksModal, setShowTasksModal] = useState(false);
  const taskRef = useRef<HTMLDivElement>(null);

  const handleOnCloseTaskModal = () => setShowTasksModal(false);
  const handleOnClickTasksModal = async () => {
    setShowTasksModal((prev) => !prev);
    if (
      showTasksModal ||
      !selectedOrganization ||
      selectedOrganization.subscription_plan === 'free_plan'
    ) {
      setTasks([]);
      return;
    }
  };

  const pendingTasksTotal = user?.tasks ? user?.tasks.total - user?.tasks.total_completed : 0;
  return (
    <div className='header-tasks'>
      <NotificationBubble total={pendingTasksTotal}>
        <div
          id='task_icon'
          className={`tasks-icon  ${
            showTasksModal || window.location.pathname === ROUTES.TODOS ? '' : ''
          }`}
          ref={taskRef}>
          {/* {showTasksModal || window.location.pathname === ROUTES.TODOS ? (
            <img src='/images/icons/clipboardOnButton.svg' alt='tasks' />
          ) : (
            <img src='/images/icons/clipboard.svg' alt='tasks' />
          )} */}
          <Button
            lookAndFeel={'secondary'}
            text={t('sideMenu.tasks')}
            onClick={handleOnClickTasksModal}
            size='small'
            id='tasks'
            iconNode={<Icon icon='tasks_2' color='gray-dark' />}
          />
        </div>
      </NotificationBubble>
      {showTasksModal && (
        <TasksModal
          tasks={tasks}
          total={total}
          totalCompleted={totalCompleted}
          handleOnClickTasksModal={handleOnCloseTaskModal}
          taskRef={taskRef}
        />
      )}
    </div>
  );
};

export default TasksButton;
