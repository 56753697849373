import { useTranslation } from 'react-i18next';

const useSortingOptions = () => {
  const { t } = useTranslation();
  const options = [
    {
      id: 'data_point_name',
      name: t('input.sorting.datapoints.data_point_name')
    }
  ];
  return options;
};

export default useSortingOptions;
