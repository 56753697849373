import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../../ui/button/Button';
import Icon from '../../../ui/icon/Icon';
import NotificationBubble from '../../../ui/notificationBubbleWrapper/NotificationBubbleWrapper';
import NotificationsModal from './notificationsModal/NotificationsModal';
import { localStorageGetItem } from '../../../../utils/localStorage';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { UserContext } from '../../../../context/userContext';
import { useTranslation } from 'react-i18next';
import useGetData from './hooks/useGetData';

const NotificationsButton = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'header' });
  const [showModal, setShowModal] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);
  const selectedOrganization = useSelectedOrganization();

  const user = useContext(UserContext);

  const { data, setData } = useGetData();

  useEffect(() => {
    const notificationsToSet = localStorageGetItem('notifications');

    const filteredByOrgId = notificationsToSet?.filter(
      (elem: NotificationBackend) =>
        elem.message.params.notification.organization_id === selectedOrganization?.id
    );
    setData(filteredByOrgId);
  }, [selectedOrganization?.id]);

  let total = 0;

  if (data) {
    total += data.filter((elem) => !elem.read).length;
  }

  if (user?.tasks) {
    total += user?.tasks.total - user?.tasks.total_completed;
  }

  return (
    <div style={{ position: 'relative' }} ref={notificationRef}>
      <NotificationBubble total={total > 0 ? total : -1}>
        <Button
          lookAndFeel={'primary'}
          text={t('notifications')}
          iconNode={<Icon icon={'bell'} color={'white'} />}
          size='small'
          onClick={() => setShowModal(!showModal)}
        />
      </NotificationBubble>
      {showModal && (
        <NotificationsModal
          data={data ?? []}
          notificationRef={notificationRef}
          closeNotifications={() => setShowModal(false)}
          setData={setData}
        />
      )}
    </div>
  );
};

export default NotificationsButton;
