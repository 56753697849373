import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ScenarioNode } from '../types/LCAScenarios';
import { buildNodes, buildNodesFromBlocks } from '../utils/builders';
import { NodeChange, applyNodeChanges } from 'reactflow';
import { LCABlock } from '../../types/LCABlock';

type Params = {
  lcaBlocks: LCABlock[];
  isLoading: boolean;
};

export const useLCANodes = ({ lcaBlocks, isLoading }: Params) => {
  const [nodes, setNodes] = useState<ScenarioNode[]>([]);

  useEffect(() => {
    if (isLoading) return;

    setNodes(buildNodesFromBlocks(lcaBlocks));
  }, [lcaBlocks, isLoading]);

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((prevNodes) => {
      return buildNodes(applyNodeChanges(changes, prevNodes));
    });
  }, []);

  const nodesMap = useMemo(
    () =>
      nodes.reduce((acc, node) => {
        acc.set(node.id, node);
        return acc;
      }, new Map<string, ScenarioNode>()),
    [nodes]
  );

  return [nodes, setNodes, onNodesChange, nodesMap] as const;
};
