import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { SIZE_PAGINATION_SMALL } from '../../../../../../constants';
import useSelectedOrganization from '../../../../../../customHooks/useSelectedOrganization';
import { editElement } from '../../../../../../lib/ReactQuery/utils';
import { getOrganizationDatapoints } from '../../../../../../services/api/esg';
import { EsgMetricsEnhanced } from '../../../../../../types/entities/esgMetrics';
import { IFilter, ISorter } from '../../../../../../utils/url';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

type Params = {
  filters: IFilter[];
  sorters: ISorter[];
  mode?: string;
  startDate: string;
  endDate: string;
};

export const useGetMetrics = ({ filters, sorters, mode, startDate, endDate }: Params) => {
  const { i18n } = useTranslation('translation', { keyPrefix: 'controlPanel.metrics.list' });
  const org_id = useSelectedOrganization()?.id;
  const queryClient = useQueryClient();

  const getTranslationsByPrefix = (keyPrefix: string) => {
    const currentLanguage = i18n.language;
    const translations = i18n.getResourceBundle(currentLanguage, 'translation');
    if (!translations) return null;

    const keysWithPrefix = keyPrefix.split('.');
    let subTranslations = translations;
    keysWithPrefix.forEach((key) => {
      subTranslations = subTranslations?.[key];
    });

    return subTranslations || {};
  };

  const memoizedFilters = useMemo(() => filters, [filters]);
  const memoizedSorters = useMemo(() => sorters, [sorters]);

  const { data, fetchNextPage, isLoading, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [
      'metrics',
      org_id,
      JSON.stringify(memoizedFilters),
      JSON.stringify(memoizedSorters),
      mode,
      startDate,
      endDate
    ],
    queryFn: (page) => {
      const searchByNames: string[] = [];
      memoizedFilters.forEach((filter) => {
        if (filter.field === 'name') {
          const subTranslations = getTranslationsByPrefix('controlPanel.metrics.list');
          Object.keys(subTranslations).forEach((key) => {
            if (subTranslations[key].includes(filter.value)) {
              searchByNames.push(key);
            }
          });
        }
      });

      // const newNameFilters: IFilter[] = searchByNames.map((name) => ({
      //   field: 'name',
      //   value: name,
      //   type: 'il'
      // }));

      const modifiedFilters: IFilter[] = [
        ...memoizedFilters.filter((filter) => filter.field !== 'name'),
        {
          field: 'name',
          value: searchByNames,
          type: 'in'
        }
      ];

      return getOrganizationDatapoints(
        startDate,
        endDate,
        modifiedFilters,
        memoizedSorters,
        page.pageParam
      );
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage || lastPage.page * SIZE_PAGINATION_SMALL >= lastPage.total) {
        return undefined;
      }
      return lastPage.page + 1;
    },
    initialPageParam: 1
  });

  // useEffect(() => {
  //   queryClient.invalidateQueries([
  //     'metrics',
  //     org_id,
  //     JSON.stringify(memoizedFilters || []),
  //     JSON.stringify(memoizedSorters || []),
  //     mode,
  //     startDate,
  //     endDate
  //   ]);
  // }, [memoizedFilters, memoizedSorters, org_id, mode, startDate, endDate, queryClient]);

  console.log('data in hook', data);

  const dataParsed = data?.pages.reduce(
    (acc, page) => ({
      page: page.page,
      total: page.total,
      total2: page.total2,
      items: [...acc.items, ...page.items]
    }),
    {
      page: 1,
      total: 0,
      total2: 0,
      items: []
    }
  );

  const editMetric = (newValue: EsgMetricsEnhanced, key: keyof EsgMetricsEnhanced = 'name') => {
    queryClient.setQueryData(
      [
        'metrics',
        org_id,
        JSON.stringify(memoizedFilters),
        JSON.stringify(memoizedSorters),
        mode,
        startDate,
        endDate
      ],
      editElement(newValue, key)
    );
  };

  return {
    data: dataParsed ?? {
      page: 1,
      total: 0,
      total2: 0,
      items: []
    },
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    editElement: editMetric
  };
};
