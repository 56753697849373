import ReactFlow, { Background, BackgroundVariant, Controls, Edge, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';

import { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { withReactFlow } from '../../../../lib/ReactFlow/withReactFlow';
import { useACVId } from '../hooks/useACVId';
import { useCreateLCAConnection } from '../hooks/useCreateLCAConnection';
import { useCreateNode } from '../hooks/useCreateNode';
import { useDeleteLCAConnection } from '../hooks/useDeleteLCAConnection';
import { useLCABlocks } from '../hooks/useLCABlocks';
import { useLCAEdges } from '../hooks/useLCAEdges';
import { useLCANodes } from '../hooks/useLCANodes';
import { usePatchNode } from '../hooks/usePatchNode';
import { EDGE_TYPES, NODE_COMPONENTS } from '../reactFlowConfig';
import { nodeToSubmit } from '../schema/nodeToSubmit';
import { ModalHandler } from './ModalHandler';
import { ModalData } from '../types/LCAScenarios';

export const LCADiagram = withReactFlow(() => {
  const { acv_id } = useACVId();
  const { createNode } = useCreateNode({ acv_id });
  const { patchNode } = usePatchNode({ acv_id });
  const { screenToFlowPosition } = useReactFlow();
  const { lcaBlocks, isLoading: loadingLCABlocks } = useLCABlocks({ acv_id });

  const { deleteConnection } = useDeleteLCAConnection();
  const { createConnection } = useCreateLCAConnection();

  const [edges, _setEdges, onEdgesChange] = useLCAEdges({
    lcaBlocks: lcaBlocks ?? [],
    isLoading: loadingLCABlocks
  });

  const [nodes, _setNodes, onNodesChange, nodesMap] = useLCANodes({
    lcaBlocks: lcaBlocks ?? [],
    isLoading: loadingLCABlocks
  });

  const [isDeletable, setIsDeletable] = useState(false);
  const [objectToSubmit, setObjectToSubmit] = useState<
    | {
        type?: 'process' | 'material';
        target?: string;
        coordinate_x?: number;
        coordinate_y?: number;
      }
    | undefined
  >();
  const [modalData, setModalData] = useState<ModalData | undefined>();

  const [_collected, drop] = useDrop(
    () => ({
      accept: 'lca-node',
      drop: (item: { type: 'process' | 'material' }) => {
        setObjectToSubmit((prev) => ({
          ...prev,
          type: item.type,
          target: modalData?.target
        }));
      }
    }),
    [modalData?.target]
  );

  const handleDeleteConnection = useCallback(async (edges: Edge[]) => {
    await Promise.all(
      edges.map((edge) => {
        deleteConnection({ acv_id, source_id: edge.source, target_id: edge.target });
      })
    );
  }, []);

  useEffect(() => {
    const validation = nodeToSubmit.safeParse(objectToSubmit);

    if (validation.success) {
      createNode({
        ...validation.data,
        acv_id
      });

      setObjectToSubmit(undefined);
    }
  }, [JSON.stringify(objectToSubmit)]);

  const final_product = lcaBlocks?.find(
    (block) => block.type === 'product' && block.outputs.length === 0
  )?.name;

  return (
    <ModalHandler.Root modalData={modalData} setModalData={setModalData}>
      <ModalHandler.Main final_product={final_product ?? ''} />

      <div className='flex-grow relative border-box on-card-gray-bg-color card-border-color border-1 border-solid rounded-8'>
        <ReactFlow
          nodes={nodes}
          nodeTypes={NODE_COMPONENTS}
          edges={edges}
          edgeTypes={EDGE_TYPES}
          proOptions={{ hideAttribution: true }}
          fitView
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgesDelete={handleDeleteConnection}
          nodeDragThreshold={3}
          onNodeDragStop={async (_event, node) => {
            await patchNode({
              acv_id,
              node_id: node.id,
              coordinate_x: node.position.x,
              coordinate_y: node.position.y
            });
          }}
          onDrop={(event) => {
            const position = screenToFlowPosition({
              x: event.clientX,
              y: event.clientY
            });

            setObjectToSubmit((prev) => ({
              ...prev,
              coordinate_x: position.x,
              coordinate_y: position.y
            }));
          }}
          onSelectionChange={({ nodes, edges }) => {
            const newIsDeletable =
              !nodes.length &&
              !edges?.some((edge) => nodesMap.get(edge.target)?.type === 'product');

            setIsDeletable(newIsDeletable);
          }}
          onConnect={({ source, target }) => {
            if (!source || !target) return;

            createConnection({ acv_id, source_id: source, target_id: target });
          }}
          deleteKeyCode={isDeletable ? ['Backspace', 'Delete'] : ''}
          ref={drop}>
          <Controls />
          <Background variant={BackgroundVariant.Lines} gap={48} />
        </ReactFlow>
      </div>
    </ModalHandler.Root>
  );
});
