import { useTranslation } from 'react-i18next';
import { ISecondaryCodeFront } from '../../../../../../types/secondaryCode';
import { StatusTag } from '../../../../../ui/statusTag';
import { STATUS_I18N_KEYS } from '../../constants';
import { PurchaseFramework } from '../../../../../../utils/mapToPurchaseFramework';
import cnaeDescriptions from '../../../../../../constants/json/cnae_description.json';
import naceDescriptions from '../../../../../../constants/json/nace_description.json';
import sicDescriptions from '../../../../../../constants/json/sic_description.json';
import { Column } from '../../../../../../types/table';
import { Status } from '../../../../../../types/utilsEnums/status';

const DESCRIPTION_MAP = {
  [PurchaseFramework.CNAE]: cnaeDescriptions,
  [PurchaseFramework.NACE]: naceDescriptions,
  [PurchaseFramework.SIC]: sicDescriptions
} as const;

export const useColumns = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'codeManagement.secondaryCode.columns'
  });

  const columns: Column<ISecondaryCodeFront>[] = [
    {
      title: t('secondaryCode'),
      dataIndex: 'code',
      key: 'code',
      width: 150
    },
    {
      title: t('type'),
      dataIndex: 'framework',
      key: 'framework',
      width: 200
    },
    {
      title: t('description'),
      dataIndex: 'code',
      key: 'description',
      render: (_, item) => {
        const descriptions = DESCRIPTION_MAP[item.framework];

        if (item.code in descriptions) {
          return descriptions[item.code as keyof typeof descriptions];
        }

        return '-';
      }
    },
    {
      title: t('primaryCode'),
      dataIndex: 'primaryCode',
      key: 'primaryCode',
      render: (code) => code || '-',
      width: 200
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status && (
          <StatusTag
            status={status as Status}
            text={STATUS_I18N_KEYS[status as keyof typeof STATUS_I18N_KEYS]}
          />
        )
    }
  ];

  return { columns };
};
