import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ACADEMY_URL, PRIVACY_POLICY_URL } from '../../../../constants';
import { ROUTES } from '../../../../constants/routes';
import { authLogout } from '../../../../services/authService';
import NameIcon from '../../../ui/nameIcon/NameIcon';
import { UserContext } from '../../../../context/userContext';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { TutorialContext } from '../../interactiveTutorial/mainTutorial/InteractiveTutorial';
import DataQuality from './components/dataQuality/DataQuality';
import useGetData from './hooks/useGetData';
import LoaderStandard from '../../../ui/loaders/loaderStandard/LoaderStandard';

type Props = {
  handleOnClickProfile: () => void;
  userRef: any;
};
type Subsection = {
  id: string;
  name: string;
  onClick: () => void;
};
type Section = {
  icon: string;
  text: string;
  onClick: () => void;
  subSections: Subsection[];
  conditionSubSections: boolean;
  hide?: boolean;
};
function UserModal({ handleOnClickProfile, userRef }: Props) {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const [showLanguages, setShowLanguages] = useState(false);

  const selectedOrganization = useSelectedOrganization();

  const role = selectedOrganization?.role;

  const tutorial = useContext(TutorialContext);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { dataQualityPercentage, sectorComparison, loading } = useGetData();

  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        userRef.current &&
        !userRef.current.contains(event.target) &&
        !tutorial?.active
      ) {
        handleOnClickProfile();
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [wrapperRef.current, tutorial?.active]);

  const navigateToProfile = () => {
    navigate(ROUTES.PROFILE, { replace: true });
    handleOnClickProfile();
  };

  const navigateToMembers = () => {
    navigate(ROUTES.MEMBERS, { replace: true });
    handleOnClickProfile();
  };

  const logout = () => {
    authLogout();
  };

  const onClickChangeLang = () => {
    setShowLanguages(!showLanguages);
  };

  const navigateToLegal = () => {
    window.open(PRIVACY_POLICY_URL);
  };

  const navigateToTutorials = () => {
    type AcademyUrl = {
      [key: string]: string;
    };

    const academyUrl: AcademyUrl = ACADEMY_URL;
    let url = academyUrl.en;
    if (academyUrl[i18n.resolvedLanguage]) {
      url = academyUrl[i18n.resolvedLanguage];
    }

    window.open(url);
  };

  const handleShowInteractiveTutorial = () => {
    navigate(ROUTES.IMPROVE_DASHBOARD, { replace: true });
    tutorial?.handleStart();
    handleOnClickProfile();
  };

  const sections: Section[] = [
    {
      icon: '/images/icons/person.svg',
      text: t('header.myProfile'),
      onClick: navigateToProfile,
      subSections: [],
      conditionSubSections: false,
      hide: selectedOrganization?.role === 'fund'
    },
    {
      icon: '/images/icons/people.svg',
      text: t('header.members'),
      onClick: navigateToMembers,
      subSections: [],
      conditionSubSections: false,
      hide: selectedOrganization?.role === 'fund'
    },
    {
      icon: '/images/icons/academy.svg',
      text: t('header.tutorials'),
      onClick: navigateToTutorials,
      subSections: [],
      conditionSubSections: false
    },
    {
      icon: '/images/icons/language.svg',
      text: t('header.changeLanguage'),
      onClick: onClickChangeLang,
      subSections: [
        { id: 'en', name: t('languages.en'), onClick: () => i18n.changeLanguage('en') },
        { id: 'es', name: t('languages.es'), onClick: () => i18n.changeLanguage('es') },
        { id: 'pt', name: t('languages.pt'), onClick: () => i18n.changeLanguage('pt') }
      ],
      conditionSubSections: showLanguages
    },
    {
      icon: '/images/icons/book.svg',
      text: t('header.legal'),
      onClick: navigateToLegal,
      subSections: [],
      conditionSubSections: false
    },
    {
      icon: '/images/icons/person.svg',
      text: t('header.showTutorial'),
      onClick: handleShowInteractiveTutorial,
      subSections: [],
      conditionSubSections: false,
      hide: selectedOrganization?.role === 'fund'
    },
    {
      icon: '/images/icons/logout.svg',
      text: t('header.logout'),
      onClick: logout,
      subSections: [],
      conditionSubSections: false
    }
  ];

  if (!user) return null;

  const { first_name, last_name, profile_img_url } = user;

  return (
    <div className='user-modal modal-box-shadow main-bg-color solid-border' ref={wrapperRef}>
      <div className='user-modal__user-info'>
        {profile_img_url ? (
          <img src={user.profile_img_url} className='round profile-img' alt='profile' />
        ) : (
          <NameIcon name={first_name} lastName={last_name} size='medium' />
        )}
        <div className='user-description'>
          <h1 className='small-card-font'>{`${first_name} ${last_name}`}</h1>
          <p className='body3-font on-light-text-color2'>{selectedOrganization?.company_name}</p>
          {role && <p className='body3-font on-light-text-color2'>{t(`header.${role}`)}</p>}
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2rem'
          }}>
          <LoaderStandard />
        </div>
      ) : (
        <DataQuality
          dataQualityPercentage={dataQualityPercentage}
          sectorComparison={sectorComparison}
        />
      )}
      <ul className='card-border-color'>
        {sections.map((section) => (
          <>
            {!section.hide && (
              <>
                <li className='body1-bold-font' onClick={section.onClick} key={section.text}>
                  <div className='icon-text-wrapper'>
                    {section.icon && <img src={section.icon} alt='person' />}
                    <span className={!section.icon ? 'only-text' : ''}>{section.text}</span>
                  </div>
                  <img
                    className={`chevron ${section.conditionSubSections ? 'rotate45' : ''}`}
                    src='/images/icons/chevron.svg'
                    alt='chevron'
                  />
                </li>
                {section.conditionSubSections && (
                  <div className='subsections'>
                    {section.subSections.map((subsection) => (
                      <span className='body1-font' onClick={subsection.onClick} key={subsection.id}>
                        {subsection.name}
                      </span>
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        ))}
      </ul>
    </div>
  );
}

export default UserModal;
