import React from 'react';
import './styles.scss';
type Props = {
  descriptions: string[];
};
const DescriptionBox = ({ descriptions }: Props) => {
  return (
    <div className='description-box icon-bg-color'>
      <ul>
        {descriptions.map((elem) => (
          <li key={elem} className='body1-font'>
            {elem}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DescriptionBox;
