import { createContext, useEffect, useState } from 'react';
import Modal from '../../../../ui/modal/Modal';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { materialFormSchema } from './materialFormSchema';
import { FirstStep } from './FirstStep';
import { FormType } from './constants';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import { SecondStep } from './SecondStep';
import { useValidate } from './useValidate';
import { useLCAMaterial } from '../../hooks/useLCAMaterial';
import { useACVId } from '../../hooks/useACVId';
import { buildFormFromMetadata } from './build';
import { useModalContext } from '../ModalHandler';

type MaterialModalContextType = {
  node_id: string | null;
  setNodeId: React.Dispatch<React.SetStateAction<string | null>>;
};

const MaterialModalContext = createContext<MaterialModalContextType | null>(null);

const Provider: React.FC = ({ children }) => {
  const [node_id, setNodeId] = useState<string | null>(null);

  return (
    <MaterialModalContext.Provider value={{ node_id, setNodeId }}>
      {children}
    </MaterialModalContext.Provider>
  );
};

type Props = {
  final_product: string;
};

const STEPS = [
  {
    id: 'name',
    text: 'lcaDetail.processFlow.materialModal.basicInfo'
  },
  {
    id: 'material',
    text: 'lcaDetail.processFlow.materialModal.material'
  }
] as const;

export const SelectMaterialModal = ({ final_product }: Props) => {
  const { modalData, setModalData } = useModalContext();
  const { acv_id } = useACVId();

  const metadata = useLCAMaterial({ acv_id, node_id: modalData?.target ?? '' });

  const [form, setForm] = useState<FormType>(buildFormFromMetadata(metadata));
  const { t } = useLCATranslation();

  const { errors, validate, setErrors } = useValidate();

  const { steps, stepSelected, handleNext, handleSelect } = useStepCarousel({
    stepsText: STEPS.map((step) => ({
      id: step.id,
      text: t(step.text)
    }))
  });

  useEffect(() => {
    setForm(buildFormFromMetadata(metadata));
    handleSelect(STEPS[0].id);
    setErrors(null);
  }, [modalData?.target]);

  const handleSelectStep = (id: string) => {
    const isValid = validate(form, materialFormSchema);

    if (!isValid) {
      return;
    }

    handleSelect(id);
  };

  return (
    <Modal.WithPortal
      show={Boolean(modalData?.target) && modalData?.type === 'edit_material'}
      onClose={() => setModalData(undefined)}
      width='720px'>
      <Modal.Header
        title={t('lcaDetail.processFlow.materialModal.title')}
        description={t('lcaDetail.processFlow.materialModal.description', { final_product })}
      />
      <StepCarouselList
        steps={steps}
        handleSelect={handleSelectStep}
        style={{ marginBottom: '1rem' }}
      />

      {stepSelected?.id === 'name' && (
        <FirstStep
          form={form}
          setForm={setForm}
          errors={errors}
          validate={validate}
          handleNext={handleNext}
        />
      )}
      {stepSelected?.id === 'material' && (
        <SecondStep form={form} setForm={setForm} errors={errors} validate={validate} />
      )}
    </Modal.WithPortal>
  );
};

SelectMaterialModal.Root = Provider;
