import { validate } from 'email-validator';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import Button from '../ui/button/Button';
import InputText from '../ui/formComponents/inputText/InputText';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<ErrorType[]>([]);

  const sendToLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    const newErrors = errors.filter((elem) => elem.error !== 'email');
    setErrors(newErrors);
  };

  const sendToOnboarding = () => {
    navigate(ROUTES.ONBOARDING);
  };

  const handleSendMail = () => {
    const newErrors = [...errors];
    if (!validate(email) && !errors.some((elem) => elem.error === 'email')) {
      newErrors.push({ error: 'email' });
    }
    setErrors(newErrors);
    // send mail to change password
  };

  return (
    <section className='onboarding'>
      <div className='onboarding-section'>
        <img className='onboarding-section__logo' src='/images/logoBlack.svg' alt='logo' />
        <div className='onboarding-section__header'>
          <h1 className='headline1-font on-light-text-color'>{t('forgotPassword.title')}</h1>
          <h3 className='subtitle1-font on-light-text-color'>{t('forgotPassword.subtitle')}</h3>
        </div>
        <div className='onboarding-section__login-form'>
          <InputText
            label={t('login.email')}
            icon='/images/icons/envelope.svg'
            placeholder={t('login.emailPlaceholder')}
            value={email}
            onChangeValue={onChangeEmail}
            error={errors.find((elem) => elem.error === 'email')}
          />
          <Button lookAndFeel='primary' text={t('forgotPassword.send')} onClick={handleSendMail} />
          <p className='on-light-text-color body1-bold-font' onClick={sendToLogin}>
            {t('forgotPassword.remember')}
          </p>

          <div className='flex onboarding-section__login-form__footer'>
            <span className='span-font on-light-text-color'>
              {t('forgotPassword.dontHaveAccount')}
            </span>
            <span className='span-font highlight-text-color' onClick={sendToOnboarding}>
              {t('login.create')}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
