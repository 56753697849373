import { MarkerType, type Edge, type Node } from 'reactflow';
import type { LCAConnection, ScenarioNode } from '../types/LCAScenarios';
import { isInNarrowedArray } from '../../../../utils/arrays';
import { NODE_TYPES_ARRAY } from '../constants';
import { LCABlock, LCAOutputs } from '../../types/LCABlock';

import uuid from 'uuid';

export const buildNodes = (nodes: Node[]): ScenarioNode[] => {
  return nodes.map((node) => {
    if (!node.type || !isInNarrowedArray(node.type, NODE_TYPES_ARRAY)) {
      throw new Error(`Node type ${node.type} is not valid`);
    }

    return {
      ...node,
      type: node.type
    };
  });
};

export const buildPieceFromString = (piece: string) => {
  return {
    id: uuid.v4(),
    label: piece,
    type: 'piece',
    inputs: [],
    outputs: []
  };
};

export const buildPieceFromOutput = (outputPiece: LCAOutputs) => {
  if (!outputPiece) {
    return null;
  }

  return {
    id: outputPiece.id,
    label: outputPiece.name,
    type: outputPiece.type,
    inputs: [],
    outputs: []
  };
};

const buildMaterialTags = (block: LCABlock) => {
  const tags = [];

  if (block.supplier) {
    tags.push(block.supplier);
  }

  // TODO: Add quantity and unit
  /* if (block) {
    tags.push(`${connection.quantity} ${t(`units_short.${connection.unit.name}`)}`);
  }
 */
  return tags;
};

const buildPieceTags = (_block: LCABlock) => {
  // TODO: Add quantity and unit
  return [];
  /* const tags = [];

  if (connection) {
    tags.push(`${connection.quantity} ${t(`units_short.${connection.unit.name}`)}`);
  }

  return tags; */
};

const buildTags = (block: LCABlock) => {
  switch (block.type) {
    case 'material': {
      return buildMaterialTags(block);
    }
    case 'product':
    case 'piece': {
      return buildPieceTags(block);
    }
    case 'process':
      return [];
    default:
      throw new Error(`Node type ${block.type} is not valid`);
  }
};

/* const getPosition = (block: LCABlock, index: number) => {
  if (block.type === 'process') {
    return POSITIONS[2];
  }

  if (block.type === 'piece') {
    return POSITIONS[3];
  }

  return POSITIONS[index];
}; */

export const buildNodesFromBlocks = (blocks: LCABlock[]): ScenarioNode[] => {
  return blocks.map((block) => {
    const tags = buildTags(block);
    const position = {
      x: block.coordinate_x,
      y: block.coordinate_y
    };

    return {
      id: block.id,
      data: {
        id: block.id,
        label: block.name,
        inputs: block.inputs,
        outputs: block.outputs,
        tags
      },
      position,
      type: block.type
    };
  });
};

export const buildEdge = (edge: Edge): Edge => {
  return {
    ...edge,
    markerEnd: {
      type: MarkerType.ArrowClosed
    },
    type: 'lcaEdge'
  };
};

export const buildEdges = (edges: Edge[]) => {
  return edges.map(buildEdge);
};

export const buildEdgesFromBlock = (block: LCABlock): Edge[] => {
  return block.outputs.map((output) => ({
    id: `${block.id}-${output.id}`,
    source: block.id,
    target: output.id,
    markerEnd: {
      type: MarkerType.ArrowClosed
    },
    type: 'lcaEdge'
  }));
};

export const buildEdgeFromConnection = (connection: LCAConnection): Edge => {
  return {
    id: connection.id,
    source: connection.source_id,
    target: connection.target_id,
    markerEnd: {
      type: MarkerType.ArrowClosed
    },
    type: 'lcaEdge'
  };
};
