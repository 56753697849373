import { ReactFlowProvider } from 'reactflow';

export const withReactFlow = <T extends object>(Component: React.FC<T>) => {
  const WithReactFlowComponent = (props: T) => {
    return (
      <ReactFlowProvider>
        <Component {...props} />
      </ReactFlowProvider>
    );
  };

  return WithReactFlowComponent;
};
