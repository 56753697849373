import { useContext, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';
import Button from '../../../ui/button/Button';
import Icon from '../../../ui/icon/Icon';
import Modal from '../../../ui/modal/Modal';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import InputFileSingle from '../../../ui/formComponents/inputFile/InputFileSingle';
import {
  uploadOfficialSuppliers,
  uploadSecondarySuppliers
} from '../../../../services/api/purchaseSuppliers';
import { recalculatePurchasesFromOrganization } from '../../../../services/api/purchases';
import { UserContext } from '../../../../context/userContext';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';

const BlockLayout: React.FC = ({ children }) => {
  return (
    <div className='w-full bg-white p-4 border-1 border-solid border-gray-2-light rounded-4 flex flex-col gap-y-4'>
      {children}
    </div>
  );
};

const BlockTitle: React.FC = ({ children }) => {
  return <h2 className='inter weight-600 font-14'>{children}</h2>;
};

export const PurchasesUpload = () => {
  const [org_id, setOrgId] = useState<string>('');
  const [modalType, setModalType] = useState<'official_suppliers' | 'secondary_suppliers'>();
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const user = useContext(UserContext);

  return (
    <>
      <Modal.WithPortal
        show={typeof modalType !== 'undefined'}
        onClose={() => {
          setModalType(undefined);
          setFile(null);
        }}>
        <FormElementFull>
          <InputFileSingle
            handleFile={(file) => setFile(file)}
            buttonText='Upload'
            labelText='Upload CSV file'
            fileName={file?.name ?? ''}
            allowedTypes={['text/csv']}
          />
        </FormElementFull>
        <div className='flex gap-x-4'>
          <Button
            lookAndFeel='secondary'
            onClick={() => {
              setFile(null);
              setModalType(undefined);
            }}
            text='Cancelar'
          />
          <Button
            lookAndFeel='primary'
            onClick={async () => {
              if (!file || !org_id || loading) return;

              setLoading(true);
              if (modalType === 'official_suppliers') {
                await uploadOfficialSuppliers(file, org_id);
              }
              if (modalType === 'secondary_suppliers') {
                await uploadSecondarySuppliers(file, org_id);
              }

              dispatch(setNotification('Archivo subido correctamente'));
              setModalType(undefined);
              setFile(null);
              setLoading(false);
            }}
            disabled={!file || !org_id}
            loading={loading}
            text='Enviar'
          />
        </div>
      </Modal.WithPortal>
      <MainPageLayout.Layout>
        <MainPageLayout.Header
          title='Gestiona las compras de nuestros clientes'
          breadcrumb={<Breadcrumb />}
        />
        <BlockLayout>
          <BlockTitle>Acciones por organización</BlockTitle>
          <FormText
            label='ID de la organización'
            iconV2='input_text'
            value={org_id}
            placeholder='Pega aquí el ID de la organización'
            className='w-100'
            onChange={(e) => setOrgId(e.target.value)}
          />
          <div className='flex justify-between gap-x-16'>
            <div className='flex gap-x-4'>
              <Button
                disabled={!org_id}
                lookAndFeel='secondary'
                style={{ whiteSpace: 'nowrap' }}
                iconNode={<Icon icon='upload' color='gray-dark' />}
                text='Subir proveedores oficiales'
                size='small'
                onClick={() => setModalType('official_suppliers')}
              />
              <Button
                disabled={!org_id}
                lookAndFeel='secondary'
                style={{ whiteSpace: 'nowrap' }}
                iconNode={<Icon icon='upload' color='gray-dark' />}
                text='Subir proveedores secundarios'
                size='small'
                onClick={() => setModalType('secondary_suppliers')}
              />
              <Button
                disabled={!org_id}
                style={{ whiteSpace: 'nowrap' }}
                lookAndFeel='secondary'
                iconNode={<Icon icon='refresh' color='gray-dark' />}
                text='Recalcular compras con proveedores activos'
                size='small'
                onClick={async () => {
                  if (!org_id || !user?.id) return;

                  await recalculatePurchasesFromOrganization(org_id, user.id);

                  dispatch(setNotification('Se ha enviado la solicitud de recálculo'));
                }}
              />
            </div>
          </div>
        </BlockLayout>
      </MainPageLayout.Layout>
    </>
  );
};
