import { FrontLCAComponent } from '../../types/LCADetail';
import { useColorPalette } from '../ColorPaletteContext/ColorPaletteContext';
import { LCAComponentCard } from '../LCAComponentCard/LCAComponentCard';
import { LCA_COLORS_DEFAULT, LCA_COLOR_PALETTE } from '../LCADoughnutChart/constants';

type Props = {
  data?: FrontLCAComponent[];
  handleSelectSection: (index: number, component: FrontLCAComponent) => void;
  currentTotal: number;
};

export const LCAComponentTable: React.FC<Props> = ({ data, handleSelectSection, currentTotal }) => {
  const { colorPalette } = useColorPalette();

  const palette = colorPalette !== undefined ? LCA_COLOR_PALETTE[colorPalette] : LCA_COLORS_DEFAULT;

  return (
    <>
      {data?.map((item, index) => {
        const color = palette[index % palette.length];
        const percentage = (Number(item.impact) * 100) / currentTotal;

        const handleClick = () => {
          if (item.is_last) {
            return;
          }

          handleSelectSection(index, item);
        };

        return (
          <LCAComponentCard
            key={item.id}
            color={color}
            handleClick={handleClick}
            name={item.name}
            impact={item.impact}
            disabled={item.is_last}
            percentage={percentage}
            lookAndFeel='component'
          />
        );
      })}
    </>
  );
};
