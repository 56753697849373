import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { months } from '../../../../constants/months';
import { gray_color2, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Tag from '../../tag/Tag';
import HorizontalBarChartStacked from '../horizontalBarChartStacked/HorizontalBarChartStacked';

import formatNumber from '../../../../utils/formatNumber';
import './styles.scss';
import { Month } from '../../../../types/utils/months';

type Props = {
  data: YearComparisonData[];
  disabled?: boolean;
  isReport?: boolean;
};
const HorizontalBarChartComparison = ({ data, disabled, isReport = false }: Props) => {
  const { i18n } = useTranslation();

  // Calculate the max value of the addition of all the scopes for each element in the data array
  const max = Math.max(...data.map((elem) => elem.scope_1 + elem.scope_2 + elem.scope_3));

  const dataSorted = data.sort((a, b) => (a.start_date > b.start_date ? 1 : -1));

  const formatDate = (timestamp: number) => {
    const date = moment(timestamp * 1000).format('DD-MM-YYYY');
    const dateSplitted = date.split('-');
    const year = dateSplitted[2].slice(2);
    const month = parseInt(dateSplitted[1], 10);
    const day = dateSplitted[0];

    const monthFormatted = months.find((elem) => elem.number === month);
    const lang = i18n.resolvedLanguage as keyof Month['short'];
    return monthFormatted ? `${day} ${monthFormatted.short[lang]} ${year}` : '';
  };

  return (
    <div className='horizontal-bar-chart-comparison'>
      {dataSorted.map((elem, index) => (
        <div className='horizontal-bar-chart-comparison__legend' key={index}>
          <div className='horizontal-bar-chart-comparison__legend__item'>
            <Tag
              direction='column'
              tags={[
                {
                  icon1: '',
                  text: (
                    <span className='on-light-text-color body1-bold-font'>
                      {formatDate(elem.start_date)} - {formatDate(elem.end_date)}
                    </span>
                  )
                },
                {
                  icon1: '',
                  text: (
                    <span
                      className={`${
                        isReport ? 'highlight-report-text-color ' : 'highlight-text-color'
                      } body1-bold-font`}>
                      {formatNumber(
                        numberToDecimal((elem.scope_1 + elem.scope_2 + elem.scope_3) / 1000)
                      )}
                    </span>
                  ),
                  text2: <span className='on-light-text-color body1-font'>t CO₂ eq.</span>
                }
              ]}
            />
          </div>
          <HorizontalBarChartStacked
            data={
              disabled
                ? [
                    {
                      color: gray_color2,
                      value: 100
                    }
                  ]
                : [
                    {
                      color: scope1_color,
                      value: elem.scope_1 / 1000
                    },
                    {
                      color: scope2_color,
                      value: elem.scope_2 / 1000
                    },
                    {
                      color: scope3_color,
                      value: elem.scope_3 / 1000
                    }
                  ]
            }
            max={disabled ? 100 : max / 1000}
          />
        </div>
      ))}
    </div>
  );
};

export default HorizontalBarChartComparison;
