import { ChartData } from 'chart.js';
import { FrontLCAComponent } from '../../types/LCADetail';
import { LCA_COLORS_DEFAULT, LCA_COLOR_PALETTE, LCA_DISABLED_COLOR_PALETTE } from './constants';
import { ColorPaletteKey } from '../../types/ColorPalette';
import { shadeColor } from '../../../../../../utils/colors';

export const getTotal = (data: number[]) => data.reduce((acc, item) => acc + item, 0);

export const getDataset = (
  data?: FrontLCAComponent[] | readonly FrontLCAComponent[],
  palette?: ColorPaletteKey
) => {
  if (!data) {
    return {
      labels: [],
      datasets: [
        {
          data: [25, 25, 25, 25],
          borderRadius: 30,
          borderWidth: 0,
          backgroundColor: LCA_DISABLED_COLOR_PALETTE,
          hoverBackgroundColor: LCA_DISABLED_COLOR_PALETTE
        }
      ]
    };
  }

  const colorPalette =
    typeof palette === 'number' ? LCA_COLOR_PALETTE[palette] : LCA_COLORS_DEFAULT;

  const dataSet: ChartData<'doughnut', number[], unknown> = {
    labels: [],
    datasets: [
      {
        data: [],
        borderRadius: 30,
        borderWidth: 0,
        backgroundColor: colorPalette,
        hoverBackgroundColor: colorPalette.map((color) => shadeColor(color, 5))
      }
    ]
  };

  for (const { name, impact } of data) {
    dataSet.labels?.push(name);
    dataSet.datasets[0].data.push(Number(impact));
  }

  return dataSet;
};
