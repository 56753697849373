import { useTranslation } from 'react-i18next';

import { validate } from 'email-validator';
import { ChangeEvent, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

import Button from '../ui/button/Button';
import InputPassword from '../ui/formComponents/inputPassword/InputPassword';
import './styles.scss';

const CreatePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const params = useParams();

  const { email } = params;

  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState<ErrorType[]>([]);

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    const newErrors = errors.filter((elem) => elem.error !== 'password');
    setErrors(newErrors);
  };

  const handleCreatePassword = () => {
    const newErrors = [...errors];
    // min length 8, needs at least a number, a letter and a special symbol
    const passwordRegex = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
    );
    if (!passwordRegex.test(password) && !errors.some((elem) => elem.error === 'password')) {
      newErrors.push({
        error: 'password',
        description: t('login.passwordRequirement')
      });
    }
    setErrors(newErrors);

    // create password

    // send to onboarding for the user with email
    navigate(ROUTES.ONBOARDING + '/' + email);
  };

  if (!email || !validate(email)) {
    return <Navigate replace to={ROUTES.ONBOARDING} />;
  }
  return (
    <section className='onboarding'>
      <div className='onboarding-section'>
        <img className='onboarding-section__logo' src='/images/logoBlack.svg' alt='logo' />
        <div className='onboarding-section__header'>
          <h1 className='headline1-font on-light-text-color'>{t('createPassword.title')}</h1>
          <h3 className='create-password subtitle1-font on-light-text-color'>
            {t('createPassword.subtitle')}
          </h3>
        </div>
        <div className='onboarding-section__login-form'>
          <InputPassword
            label={t('createPassword.password')}
            icon='/images/icons/lock.svg'
            placeholder={t('createPassword.passwordPlaceholder')}
            value={password}
            onChangeValue={onChangePassword}
            error={errors.find((elem) => elem.error === 'password')}
          />
          <Button
            lookAndFeel='primary'
            text={t('createPassword.almostThere')}
            onClick={handleCreatePassword}
          />
        </div>
      </div>
    </section>
  );
};

export default CreatePassword;
