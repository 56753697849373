import React from 'react';
import { gradient_color, gray_color2 } from '../../../../../../styles/colors';
import HorizontalBar from '../../../../../ui/horizontalBar/HorizontalBar';
import { useTranslation } from 'react-i18next';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';

type Props = {
  title: string;
  total: number;
  completed: number;
  uncertainty: number;
};
const FrameworkProgressBar = ({ title, total, completed, uncertainty }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });
  return (
    <div className='flex items-center justify-between gap-1'>
      <div className='font-12 weight-600' style={{ whiteSpace: 'nowrap' }}>
        {title}
      </div>
      <div className='flex items-center gap-2' style={{ width: '80%' }}>
        <TooltipWrapper
          size='small'
          text={
            <ul style={{ margin: 0, paddingInlineStart: '0.5rem' }}>
              <li>
                {t('barTooltip.withData', {
                  total: completed
                })}
              </li>
              <li>
                {t('barTooltip.pending', {
                  total: total - completed
                })}
              </li>
              {/* <li>
                {t('barTooltip.uncertainty', {
                  uncertainty
                })}
              </li> */}
            </ul>
          }
          style={{ width: '100%' }}>
          <HorizontalBar
            total={100}
            values={[
              {
                value: Math.round((completed / total) * 100),
                color: gradient_color
              }
            ]}
            style={{ borderRadius: '8px', marginLeft: 'auto', background: gray_color2 }}
          />
        </TooltipWrapper>
        <span className='font-12 weight-400' style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
          {total === 0 ? 0 : Math.round((completed / total) * 100)}% {t('withData')}
        </span>
      </div>
    </div>
  );
};

export default FrameworkProgressBar;
