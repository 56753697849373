import { error_color, gradient_color, gradient_color_secondary, main_bg_color } from './colors';

export const basicNuvoStyle = {
  globals: {
    fontFamily: 'Inter'
  },
  buttons: {
    primary: {
      color: main_bg_color,
      background: gradient_color,
      borderRadius: '8px',
      border: '1px solid #ffffff',
      ':hover': { background: gradient_color_secondary }
    },
    secondary: {
      borderRadius: '8px'
    },
    link: {
      background: 'transparent'
    }
  },
  columnMatch: {
    notMatchingValue: {
      root: {
        color: error_color
      },
      icon: {
        color: error_color
      }
    }
  }
};

export const highlightLinkNuvo = {
  buttons: {
    primary: {
      color: main_bg_color,
      background: gradient_color,
      borderRadius: '8px',
      border: '1px solid #ffffff',
      ':hover': { background: gradient_color_secondary }
    },
    secondary: {
      borderRadius: '8px'
    }
  }
};
