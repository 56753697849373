import { useParams } from 'react-router-dom';
import { useLCAFolders } from '../../hooks/useLCAFolders';
import { PageParams } from '../../types/PageParams';
import { useLCAMethodology } from '../LCAMethodologyContext/LCAMethodologyContext';
import { LCAComponentCard } from '../LCAComponentCard/LCAComponentCard';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import { useColorPalette } from '../ColorPaletteContext/ColorPaletteContext';
import { LCA_COLORS_DEFAULT, LCA_COLOR_PALETTE } from '../LCADoughnutChart/constants';
import { LCAFoldersResponse } from '../../types/LCADetail';
import { numberToScientificNotation } from '../../../../../../utils/numberToDecimal';

type Props = {
  handleSelectSection: (index: number, folder: keyof LCAFoldersResponse) => void;
  currentTotal: number;
};

export const LCAFoldersTable: React.FC<Props> = ({ handleSelectSection, currentTotal }) => {
  const { id } = useParams<PageParams>();

  const { colorPalette } = useColorPalette();

  const { t } = useLCATranslation();

  const { methodology } = useLCAMethodology();

  const { data } = useLCAFolders({
    id,
    methodologyId: methodology?.id,
    impactCategory: methodology?.impact_category
  });

  const palette = colorPalette !== undefined ? LCA_COLOR_PALETTE[colorPalette] : LCA_COLORS_DEFAULT;

  if (!data) {
    return null;
  }

  return (
    <>
      {Object.entries(data).map(([title, folder], index) => {
        const impact = numberToScientificNotation(
          folder.reduce((acc, curr) => acc + curr.impacts, 0)
        );

        const percentage = (Number(impact) / currentTotal) * 100;

        return (
          <LCAComponentCard
            key={title}
            name={t(`lcaDetail.${title}`)}
            handleClick={() => handleSelectSection(index, title as keyof LCAFoldersResponse)}
            color={palette[index % palette.length]}
            impact={impact}
            percentage={percentage}
            lookAndFeel='folder'
          />
        );
      })}
    </>
  );
};
