import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import NotificationContent from './components/notificationContent/NotificationContent';
import EmptyContent from './components/emptyContent/EmptyContent';
import { localStorageGetItem, localStorageSetItem } from '../../../../../utils/localStorage';
import SlideToggle from '../../../../ui/formComponents/slideToggle/SlideToggle';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import { UserContext } from '../../../../../context/userContext';
import { IGetBackendTask } from '../../../../../types/entities/task';
import TaskContent from './components/taskContent/TaskContent';
import useStepsText from './hooks/useStepsText';
import Button from '../../../../ui/button/Button';
import Icon from '../../../../ui/icon/Icon';
import { ROUTES } from '../../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { convertStringToDateBackend } from '../../../../../utils/convertDates';

type Props = {
  data: NotificationBackend[];
  setData: Dispatch<SetStateAction<NotificationBackend[]>>;
  notificationRef: RefObject<HTMLDivElement>;
  closeNotifications: () => void;
};
const NotificationsModal = ({ data, setData, notificationRef, closeNotifications }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });
  const { t: tHeader } = useTranslation('translation', { keyPrefix: 'header' });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const user = useContext(UserContext);

  const navigate = useNavigate();

  const tasks = user?.tasks ?? undefined;

  const stepsText = useStepsText({ data });

  const [showNonRead, setShowNonRead] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        closeNotifications();
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [wrapperRef.current]);

  //######## UNCOMMENT when we have tasks inside aswell #########
  const { steps, stepSelected, handleSelect } = useStepCarousel({
    stepsText
  });

  const handleOnClick = (notification: NotificationBackend) => {
    closeNotifications();

    // Set notification.read to true in localStorage 'notifications'
    const notifications = localStorageGetItem('notifications');
    const notificationIndex = notifications.findIndex(
      (n: NotificationBackend) =>
        n.timestamp === notification.timestamp &&
        n.message.params.notification.message.code ===
          notification.message.params.notification.message.code &&
        n.message.params.notification.file_id === notification.message.params.notification.file_id
    );

    if (notificationIndex < 0) return;

    notifications[notificationIndex].read = true;

    localStorageSetItem('notifications', [...notifications]);
    setData([...notifications]);
  };

  const handleOnClickTask = (task: IGetBackendTask) => {
    closeNotifications();
  };

  const renderContent = () => {
    const dataFiltered = showNonRead ? data.filter((notification) => !notification.read) : data;
    const tasksFiltered = showNonRead
      ? tasks?.items.filter((task) => !task.completed)
      : tasks?.items;

    const incidencesFiltered = data.filter(
      (notification) =>
        (showNonRead ? !notification.read : true) &&
        notification.message.params.notification.message.code !== 'FILE_PROCESSING_SUCCESS'
    );

    let dataToShow: {
      timestamp: number;
      elem: IGetBackendTask | NotificationBackend;
      type: string;
    }[] = [];

    if (!stepSelected?.id) return <></>;

    if (stepSelected?.id === 'all') {
      dataToShow = [
        ...dataToShow,
        ...dataFiltered.map((elem) => {
          return {
            timestamp: convertStringToDateBackend(elem.timestamp.split(' ')[0]).getTime() / 1000,
            elem,
            type: 'notification'
          };
        })
      ];
    }

    if (stepSelected?.id === 'incidences') {
      dataToShow = [
        ...dataToShow,
        ...incidencesFiltered.map((elem) => {
          return {
            timestamp: convertStringToDateBackend(elem.timestamp.split(' ')[0]).getTime() / 1000,
            elem,
            type: 'notification'
          };
        })
      ];
    }

    if ((stepSelected?.id === 'tasks' || stepSelected?.id === 'all') && tasksFiltered) {
      dataToShow = [
        ...dataToShow,
        ...tasksFiltered.map((elem) => {
          return {
            elem,
            timestamp: elem.created_at,
            type: 'task'
          };
        })
      ];
    }

    return (
      <>
        {dataToShow.length === 0 && <EmptyContent />}
        {dataToShow
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((elem, index) => {
            if (elem.type === 'notification') {
              return (
                <NotificationContent
                  notification={elem.elem as NotificationBackend}
                  key={index}
                  handleOnClick={handleOnClick}
                />
              );
            }
            if (elem.type === 'task') {
              return (
                <TaskContent
                  task={elem.elem as IGetBackendTask}
                  key={index}
                  handleOnClick={handleOnClickTask}
                />
              );
            }
            return null;
          })}
      </>
    );
  };

  return (
    <div
      className='notifications-modal modal-box-shadow main-bg-color solid-border'
      ref={wrapperRef}
      id='notifications'>
      <div className='flex justify-between items-center gap-4'>
        <span className='font-20 semibold-font'>{t('title')}</span>
        <SlideToggle
          label={t('showNonRead')}
          labelPosition='left'
          checked={showNonRead}
          setChecked={(checked: boolean) => setShowNonRead(checked)}
        />
      </div>
      <div className='flex justify-between gap-2 items-center'>
        <StepCarouselList
          steps={steps}
          handleSelect={handleSelect}
          style={{ marginTop: '1.5rem' }}
        />
        <Button
          lookAndFeel='secondary'
          iconNode={<Icon icon='tasks_2' color='gray-dark' />}
          text={tHeader('goToTasks')}
          onClick={() => {
            closeNotifications();
            navigate(ROUTES.TODOS);
          }}
          size='small'
          style={{ width: 'fit-content' }}
        />
      </div>
      <div
        className='flex-col gap-2 items-center'
        style={{
          height: '50vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          marginTop: '1.5rem'
        }}>
        {renderContent()}
      </div>
    </div>
  );
};

export default NotificationsModal;
