import { HTMLAttributes, ReactNode } from 'react';

type ICardProps = HTMLAttributes<HTMLDivElement>;

const Card = (props: ICardProps) => {
  const className = `${props.className} card dashboard-card`;
  return <div {...props} className={className} />;
};

const Header = ({ title }: { title: ReactNode }) => {
  return (
    <div className='dashboard-card__header'>
      <h4 className='headline4-font on-light-text-color' style={{ width: '100%' }}>
        {title}
      </h4>
    </div>
  );
};

const Body = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div
      className={`${className} dashboard-card__body esg-metrics-dash`}
      style={{ gridTemplateRows: 'auto' }}>
      {children}
    </div>
  );
};

Card.Header = Header;
Card.Body = Body;

export default Card;
