import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { gray_color2, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import numberToDecimal, {
  formatNumberToDecimalNonZero,
  numberToDecimalNonZero
} from '../../../../utils/numberToDecimal';
import HorizontalBarChartComparison from '../../../ui/charts/horizontalBarChartComparison/HorizontalBarChartComparison';
import ScopeTag from '../../../ui/scopeTag/ScopeTag';
import Co2Info from './co2Info/Co2Info';
import './styles.scss';

type Props = {
  dataScopes: ScopeData[];
  totalBiomass: number;
  dataYearComparison: YearComparisonData[];
  startDate: string;
  endDate: string;
  dataAccuracy: string;
  intensityMetric1?: IntensityMetricReport;
  intensityMetric2?: IntensityMetricReport;
  scopesToShow: number[];
  showTemporalComparative: boolean;
};
type PieChart = {
  title: string;
  value: number;
  color: string;
};
function Emissions({
  dataScopes,
  totalBiomass,
  dataYearComparison,
  startDate,
  endDate,
  dataAccuracy,
  intensityMetric1,
  intensityMetric2,
  scopesToShow,
  showTemporalComparative
}: Props) {
  const { t } = useTranslation();

  let pieChartData: PieChart[] = [];
  let totalCo2 = 0;
  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;

  if (dataScopes && dataScopes.length > 0) {
    dataScopes.forEach((value) => {
      if (scopesToShow.includes(value.scope)) {
        totalCo2 += value.total_emissions;
      }

      if (value.scope === 1 && scopesToShow.includes(1)) {
        scope1 += value.total_emissions;
      }
      if (value.scope === 2 && scopesToShow.includes(2)) {
        scope2 = value.total_emissions;
      }
      if (value.scope === 3 && scopesToShow.includes(3)) {
        scope3 = value.total_emissions;
      }
    });
    pieChartData = [];
    if (scopesToShow.includes(1)) {
      pieChartData.push({
        title: '',
        value: scope1 > 0 ? (scope1 * 100) / totalCo2 : 0,
        color: scope1_color
      });
    }
    if (scopesToShow.includes(2)) {
      pieChartData.push({
        title: '',
        value: scope2 > 0 ? (scope2 * 100) / totalCo2 : 0,
        color: scope2_color
      });
    }
    if (scopesToShow.includes(3)) {
      pieChartData.push({
        title: '',
        value: scope3 > 0 ? (scope3 * 100) / totalCo2 : 0,
        color: scope3_color
      });
    }

    pieChartData.sort((a, b) => {
      return b.value - a.value;
    });
  }

  const unit = 't CO₂ eq.';
  const totalCo2PieChart = totalCo2 / 1000;

  const unitBiomass = 't CO₂';
  const totalBiomassValue = totalBiomass / 1000;

  const renderCo2Information = () => {
    const value = totalCo2PieChart;
    let fontSize = 20;
    const maxDigits = 5;
    if (numberToDecimal(totalCo2).toString().length > maxDigits) {
      fontSize = 20 - (numberToDecimal(totalCo2).toString().length - maxDigits) - 2;
    }

    return (
      <div className='co2-information'>
        <div className='co2-value co2-dashboard-font-small' style={{ fontSize: `${fontSize}px` }}>
          {formatNumberToDecimalNonZero(value)}
        </div>
        <span className='co2-units on-light-text-color body1-font'>{unit}</span>
      </div>
    );
  };

  const renderScopesInfo = (totalCo2: number) => {
    return (
      <div className='scopes-wrapper'>
        {scopesToShow.includes(1) && (
          <ScopeTag
            scope={1}
            value={scope1 / 1000}
            totalCo2={totalCo2 / 1000}
            textLookAndFeel='secondary'
          />
        )}
        {scopesToShow.includes(2) && (
          <ScopeTag
            scope={2}
            value={scope2 / 1000}
            totalCo2={totalCo2 / 1000}
            textLookAndFeel='secondary'
          />
        )}
        {scopesToShow.includes(3) && (
          <ScopeTag
            scope={3}
            value={scope3 / 1000}
            totalCo2={totalCo2 / 1000}
            textLookAndFeel='secondary'
          />
        )}
      </div>
    );
  };

  if (pieChartData.length === 0 || totalCo2PieChart === 0) {
    pieChartData = [{ title: '', value: 100, color: gray_color2 }];
  }

  return (
    <div className='emissions' id='emissions'>
      {dataAccuracy ? (
        <div className='uncertainty-level on-card-gray-bg-color'>
          <span className='body2-font'>{t('ghgReport.uncertaintyLevel')}</span>
          <span className='body2-font highlight-report-text-color'>
            {t(`ghgReport.${dataAccuracy}`)}
          </span>
        </div>
      ) : null}
      <div className='card-info-co2 on-card-gray-bg-color'>
        <div className='co2-progress-bar'>
          <PieChart // your data
            data={pieChartData}
            // width and height of the view box
            viewBoxSize={[100, 100]}
            lineWidth={24}
            totalValue={100}
            rounded
            animate></PieChart>
          {renderCo2Information()}
        </div>
        {renderScopesInfo(totalCo2)}
      </div>
      <div className='emissions-info'>
        <div className='co2-emissions on-card-gray-bg-color'>
          <Co2Info
            icon={'/images/icons/fire50.svg'}
            title={t('ghgReport.fossil')}
            value={totalCo2PieChart}
            unit={unit}
          />
          <Co2Info
            icon={'/images/icons/water.svg'}
            title={t('ghgReport.biomass')}
            value={totalBiomassValue}
            unit={unitBiomass}
          />
        </div>
        <div className='co2-emissions on-card-gray-bg-color flex'>
          {intensityMetric1 && (
            <Co2Info
              icon='/images/icons/intensityMetric.svg'
              title={intensityMetric1.name}
              value={numberToDecimalNonZero(intensityMetric1.value, 3)}
              unit={intensityMetric1.unit}
            />
          )}
          {intensityMetric2 && (
            <Co2Info
              icon='/images/icons/intensityMetric.svg'
              title={intensityMetric2.name}
              value={numberToDecimalNonZero(intensityMetric2.value, 3)}
              unit={intensityMetric2.unit}
            />
          )}
        </div>
      </div>
      {showTemporalComparative && (
        <>
          <h2 className='body2-font'>{t('ghgReport.emissionsEvolution')}</h2>
          <div className='emissions-evolution'>
            <div className='scopes'>
              {scopesToShow.map((scope) => (
                <div className='scope' key={scope}>
                  <img src={`/images/icons/dotScope${scope}.svg`} alt={`scope-${scope}`} />
                  <span className='body1-bold-font'>{t(`ghgReport.scope${scope}`)}</span>
                </div>
              ))}
            </div>
            <HorizontalBarChartComparison
              data={dataYearComparison.map((elem) => {
                return {
                  ...elem,
                  scope_1: scopesToShow.includes(1) ? elem.scope_1 : 0,
                  scope_2: scopesToShow.includes(2) ? elem.scope_2 : 0,
                  scope_3: scopesToShow.includes(3) ? elem.scope_3 : 0
                };
              })}
              isReport
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Emissions;
