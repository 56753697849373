import { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { months } from '../../../../constants/months';
import { ROUTES } from '../../../../constants/routes';
import { UserContext } from '../../../../context/userContext';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { Task } from '../../../../types/entities/task';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Button from '../../../ui/button/Button';
import LoaderTables from '../../../ui/loaders/loaderTables/LoaderTables';
import { TutorialContext } from '../../interactiveTutorial/mainTutorial/InteractiveTutorial';
import TaskProgress from './components/taskProgress/TaskProgress';

type Props = {
  tasks: Task[] | null;
  total: number;
  totalCompleted: number;
  handleOnClickTasksModal: () => void;
  taskRef: any;
};
function TasksModal({ tasks, total, totalCompleted, handleOnClickTasksModal, taskRef }: Props) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();

  const [category, setCategory] = useState('measure');

  const tutorial = useContext(TutorialContext);
  const wrapperRef: any = useRef();

  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        taskRef.current &&
        !taskRef.current.contains(event.target) &&
        !tutorial?.active
      ) {
        handleOnClickTasksModal();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [wrapperRef.current, tutorial?.active]);

  const onChangeCategory = (value: string) => {
    setCategory(value);
  };

  const renderName = (task: Task) => {
    let nameParsed = t(`tasks.${task.name}`);

    const month: any = months.find(
      (elem) => elem.number === new Date(task.created_at).getMonth() + 1
    );

    if (nameParsed.includes('{{month}}') && month) {
      nameParsed = nameParsed.replace('{{month}}', month.name[i18n.resolvedLanguage]);
    } else if (nameParsed.includes('{{carbonDebt}}') && selectedOrganization) {
      const carbonDebt =
        selectedOrganization.co2e < 1000
          ? numberToDecimal(selectedOrganization.co2e).toString()
          : numberToDecimal(selectedOrganization.co2e / 1000).toString();
      const unit = selectedOrganization.co2e < 1000 ? 'kg' : 't';
      nameParsed = nameParsed.replace('{{carbonDebt}}', carbonDebt).replace('{{unit}}', unit);
    }
    return nameParsed;
  };

  const navigateToTasks = () => {
    handleOnClickTasksModal();

    navigate(ROUTES.TODOS, { state: { tasks } });
  };

  let tasksFiltered = tasks;
  if (tasks) {
    tasksFiltered = tasks.filter(
      (elem) => elem.category === category && elem.status !== 'completed'
    );
  }

  if (!selectedOrganization) return null;
  return (
    <Suspense fallback={<LoaderTables />}>
      <div
        className='tasks-modal modal-box-shadow main-bg-color solid-border'
        ref={wrapperRef}
        id='tasks'>
        <div
          className='tasks-modal-header'
          style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'left' }}>
          <span className='span-font'>{t('header.discoverYourTasks')}</span>
          {/* <img src='/images/icons/taskModal.svg' alt='tasks' /> */}
        </div>
        {/* <div className='tasks-modal-body'> */}
        <TaskProgress total={user?.tasks?.total} totalCompleted={user?.tasks?.total_completed} />
        {/* </div> */}
        <div className='buttons-task'>
          <Button
            lookAndFeel={'primary'}
            text={t('header.goToTasks')}
            onClick={navigateToTasks}
            size='small'
          />
        </div>
      </div>
    </Suspense>
  );
}

export default TasksModal;
