import { LCAMaterial } from '../../../../../services/api/lca/lcaPortfolio';

export type LCAFormMaterial = Omit<LCAMaterial, 'id'> & {
  quantity?: string;
  unit?: string;
  unit_id?: string;
};

export type FormType = {
  name: string;
  materials: LCAFormMaterial[];
};

export const INITIAL_FORM: FormType = {
  name: '',
  materials: []
};
