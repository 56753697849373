import { useTranslation } from 'react-i18next';
import { EsgMetricChart } from './EsgMetricChart';
import useData from './hooks/useData';
import './styles.scss';

type DataChartType = {
  title: string;
  value: number;
  color: string;
};

export const EsgMetrics = () => {
  const { t } = useTranslation();
  const { allData, dataMetrics, progress } = useData();

  return (
    <div className='dashboard-card card overflow-y-scroll'>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>{t('dashboard.esgMetrics')}</h4>
      </div>
      <div className='improving-cards'>
        <div className='improve-card-first-section'>
          <div className='esgMetrics-charts-container'>
            {allData.map((data: DataChartType[], index) => (
              <EsgMetricChart
                data={data}
                key={data[0].title}
                datapointsInfo={dataMetrics[index]}
                progress={progress[index]}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
