import { Status } from '../../../../types/utilsEnums/status';

export const PURCHASE_STATUS = {
  [Status.ACTIVE]: Status.ACTIVE,
  [Status.IN_REVIEW]: Status.IN_REVIEW,
  [Status.IN_PROGRESS]: Status.IN_PROGRESS
} as const;

export const AMZ_BUSINESS_ID = 'amazon_business';

export const FILTER_IN_REVIEW = {
  field: 'status',
  value: Status.IN_REVIEW,
  type: 'eq'
} as const;
