import {
  IPurchaseSupplierBackend,
  IPurchaseSupplierFrontend
} from '../../../../../../types/purchaseSupplier';

export const getPurchaseSuppliersAdapter = (
  purchaseSuppliers: IPurchaseSupplierBackend[]
): IPurchaseSupplierFrontend[] => {
  return purchaseSuppliers.map(
    ({
      purchase_code,
      business_name,
      organization_id,
      official_purchase_supplier_id,
      ...rest
    }) => ({
      ...rest,
      organizationId: organization_id,
      name: business_name,
      purchaseCode: purchase_code && {
        code: purchase_code?.code,
        codeType: purchase_code?.code_type,
        framework: purchase_code?.framework
      },
      officialPurchaseSupplierId: official_purchase_supplier_id,
      purchasesCount: rest.purchases_count
    })
  );
};
