import Button from '../../../../../ui/button/Button';
import { useFormContext } from '../FormContext';
import Icon from '../../../../../ui/icon/Icon';
import TableComponent from '../../../../../ui/table/Table';
import { useColumns } from './useColumns';
import { useState } from 'react';
import { AddInputModal } from '../../AddInputModal';
import StepCarouselList from '../../../../../ui/stepCarouselList/StepCarouselList';
import { useStepContext } from '../../../../../ui/stepCarouselList/StepContext';
import Modal from '../../../../../ui/modal/Modal';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import { buildInputsFromMaterials, buildMaterialsFromInputs } from '../utils';
import { LCAMaterial } from '../../../../../../services/api/lca/lcaPortfolio';
import { usePatchLCAProcessNode } from '../../../hooks/usePatchLCAProcessNode';
import { useACVId } from '../../../hooks/useACVId';
import { formSchema } from '../schema';
import { adaptZodErrors } from '../../../../../../adapters/zodErrors';
import ErrorText from '../../../../../ui/errorText/ErrorText';
import { useModalContext } from '../../ModalHandler';

type Props = {
  errors: {
    inputs?: ErrorType;
  } | null;
  setErrors: (errors: Props['errors']) => void;
};

export const SecondStep = ({ errors, setErrors }: Props) => {
  const { form, setForm } = useFormContext();

  const [isSelectMaterialOpen, setIsSelectMaterialOpen] = useState(false);
  const { t } = useLCATranslation();

  const { steps, handleSelect } = useStepContext();

  const deleteMaterial = (material_id: string) => () => {
    setForm((prev) => ({
      ...prev,
      inputs: prev.inputs.filter((input) => input.material_id !== material_id)
    }));
  };

  const updateMaterialQuantity = (id: string, quantity: string) => {
    setForm((prev) => ({
      ...prev,
      inputs: prev.inputs.map((input) =>
        input.material_id === id ? { ...input, quantity } : input
      )
    }));
  };

  const updateMaterialUnit = (id: string, unit: SelectOptionFormat<string>) => {
    setForm((prev) => ({
      ...prev,
      inputs: prev.inputs.map((input) =>
        input.material_id === id
          ? {
              ...input,
              unit: {
                id: unit.id,
                name: unit.name
              }
            }
          : input
      )
    }));
  };

  const { columns } = useColumns({ deleteMaterial, updateMaterialQuantity, updateMaterialUnit });

  if (isSelectMaterialOpen) {
    const goBack = () => setIsSelectMaterialOpen(false);

    const updateMaterials = (materials: LCAMaterial[]) => {
      const inputsInMaterials = form.inputs.filter((input) =>
        materials.some((material) => material.id === input.material_id)
      );

      const materialsNotInInputs = materials.filter((material) => {
        return !inputsInMaterials.some((input) => input.material_id === material.id);
      });

      const newInputs = [...inputsInMaterials, ...buildInputsFromMaterials(materialsNotInInputs)];

      setForm((prev) => ({ ...prev, inputs: newInputs }));
    };

    return (
      <AddInputModal
        goBack={goBack}
        defaultMaterials={buildMaterialsFromInputs(form.inputs)}
        updateMaterials={updateMaterials}
      />
    );
  }

  return (
    <>
      <Modal.Header
        title={t('lcaDetail.processFlow.processModal.title')}
        description={t('lcaDetail.processFlow.processModal.description')}
      />
      <div className='flex-col gap-y-4'>
        <StepCarouselList steps={steps} handleSelect={handleSelect} />
        <Modal.Content>
          <div className='flex-col gap-y-2'>
            {!form.inputs.length && (
              <span className='font-body-b2-r text-neutral-gray-20 bg-neutral-gray-warm-80 w-full py-2 px-4 border-box rounded-4'>
                {t('lcaDetail.processFlow.processModal.noInputs')}
              </span>
            )}
            {!!form.inputs.length && (
              <div className='bg-neutral-gray-warm-80 w-full p-4 border-box rounded-4'>
                <TableComponent data={form.inputs} columns={columns} loading={false} />
              </div>
            )}
            <Button
              lookAndFeel='link-dark'
              className='flex items-center gap-x-1'
              text={t('lcaDetail.processFlow.processModal.addInputs')}
              iconNode={<Icon icon='add' color='gray-dark' size='medium' />}
              onClick={() => setIsSelectMaterialOpen(true)}
            />
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Buttons setErrors={setErrors} />
        </Modal.Buttons>
        {!!errors?.inputs && <ErrorText>{errors.inputs.description}</ErrorText>}
      </div>
    </>
  );
};

type ButtonsProps = {
  setErrors: (errors: Props['errors']) => void;
};

const Buttons = ({ setErrors }: ButtonsProps) => {
  const { t } = useLCATranslation();
  const { setModalData, modalData } = useModalContext();
  const { patchLCAProcessNode, isLoading } = usePatchLCAProcessNode();

  const { acv_id } = useACVId();

  const { form } = useFormContext();

  const onCancel = () => {
    setModalData(undefined);
  };

  const onSave = async () => {
    if (!modalData?.target || modalData.type !== 'edit_process') return;

    const validationResult = formSchema.SECOND.safeParse(form);

    if (!validationResult.success) {
      setErrors(adaptZodErrors(validationResult.error, t));
      return;
    }

    await patchLCAProcessNode({ form, acv_id, nodeId: modalData.target });

    setModalData(undefined);
  };

  return (
    <>
      <Button
        lookAndFeel='secondary'
        text={t('lcaDetail.processFlow.processModal.cancel')}
        size='small'
        onClick={onCancel}
      />
      <Button
        lookAndFeel='primary'
        text={t('lcaDetail.processFlow.processModal.save')}
        size='small'
        loading={isLoading}
        onClick={onSave}
      />
    </>
  );
};

SecondStep.Buttons = Buttons;
