import {
  LCAInput,
  LCAMaterial,
  LCAProcessMetadata
} from '../../../../../services/api/lca/lcaPortfolio';
import getCountryName from '../../../../../utils/getCountryName';
import { FormProcessInput } from './FormContext';

const adaptLCAInputTypeToMaterialType = (type: LCAInput['type']): LCAMaterial['type'] => {
  if (type === 'consumption' || type === 'raw_material') {
    return 'material';
  }

  return 'energy';
};

export const buildFormFromMetadata = (metadata?: LCAProcessMetadata, lang?: string) => {
  const inputs =
    metadata?.inputs
      ?.filter((input) => !(input.type === 'raw_material'))
      .map((input) => ({
        id: input.id,
        material_id: input.material_id,
        name: input.name,
        supplier: input.supplier,
        quantity: input.quantity.toFixed(2),
        unit: {
          id: input.unit_id,
          name: input.unit
        },
        type: adaptLCAInputTypeToMaterialType(input.type),
        unit_list: input.unit_list
      })) ?? [];

  const name = metadata?.name ?? '';

  const country = metadata?.country
    ? { id: metadata.country, name: getCountryName(metadata.country, lang ?? 'es') }
    : { id: '', name: '' };

  return { name, country, inputs };
};

export const buildMaterialsFromInputs = (inputs: FormProcessInput[]): LCAMaterial[] => {
  const materials =
    inputs?.map((input) => ({
      id: input.material_id,
      material_id: input.material_id,
      name: input.name,
      supplier: input.supplier,
      type: input.type,
      country: '',
      location: '',
      impact_source_id: '',
      unit_list: []
    })) ?? [];

  return materials;
};

export const buildInputsFromMaterials = (materials: LCAMaterial[]) => {
  return materials.map((material) => ({
    id: material.id,
    material_id: material.id,
    name: material.name,
    supplier: material.supplier,
    quantity: '',
    unit: {
      id: '',
      name: ''
    },
    type: material.type,
    unit_list: material.unit_list
  }));
};
