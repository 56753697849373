import numberToDecimal from '../../../../utils/numberToDecimal';
import Bar from './Bar';
import './styles.scss';

type DataItem = {
  value: number;
  color: string;
};
type Props = {
  data: DataItem[];
  max: number;
};
const HorizontalBarChartStacked = ({ data, max }: Props) => {
  const totalValue = data.reduce((acc, item) => {
    return acc + item.value;
  }, 0);

  const dataFiltered = data.filter((item) => item.value !== 0);

  return (
    <div className='horizontal-bar-chart-stacked'>
      {dataFiltered.map((item, index) => {
        const percentageValue = (100 * item.value) / totalValue;
        return (
          <Bar
            value={item.value}
            color={item.color}
            key={index}
            totalValue={max}
            totalLength={dataFiltered.length}
            percentageValue={percentageValue}
            index={index}
          />
        );
      })}
    </div>
  );
};

export default HorizontalBarChartStacked;
