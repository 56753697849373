import { useDrag } from 'react-dnd';
import { LCACard } from '../../../Portfolio/LCADetail/components/LCACard';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { useModalContext } from '../ModalHandler';

export const DraggableMaterial = () => {
  const { t } = useLCATranslation();

  const { modalData } = useModalContext();

  const [_collected, drag, _dragPreview] = useDrag(() => ({
    type: 'lca-node',
    item: { type: NODE_TYPES.material, target: modalData?.target }
  }));

  return (
    <LCACard.Layout ref={drag} className='cursor-drag'>
      <div className='flex gap-x-4'>
        <LCACard.Icon icon={NODE_ICONS[NODE_TYPES.material]} lookAndFeel='material' />
        <div className='flex-col gap-y-1'>
          <span className='m-0 font-body-b1-b select-none'>{t('modules.material.title')}</span>
          <p className='m-0 font-body-b2-r text-neutral-gray-warm-20 select-none'>
            {t('modules.material.description')}
          </p>
        </div>
      </div>
    </LCACard.Layout>
  );
};
