import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { getCountryNameByCode } from '../../../../constants/phonePrefixes';
import { EmissionFactor } from '../../../../types/entities/emissionFactor';
import { formatNumberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import { getUniqueItemsByProperties } from '../../../../utils/removeDuplicates';
import List from '../common/list/List';

type Props = {
  emissionFactors: EmissionFactor[];
};

const getEfSourceTranslation = (source: string) => {
  if (source.toLowerCase() === 'custom') {
    return t('ghgReport.custom');
  }

  return source;
};

const EmissionFactors = ({ emissionFactors }: Props) => {
  const { t, i18n } = useTranslation();

  const renderSource = (emissionFactor: EmissionFactor) => {
    let sourceDetail = '';
    if (emissionFactor.source === 'invoice') {
      sourceDetail = t(`facility_fuels.${emissionFactor.fuel}`);
    }
    if (emissionFactor.fuel === 'electricity' || emissionFactor.fuel === 'water') {
      sourceDetail = t(`ghgReport.${emissionFactor.fuel}`);
    }
    if (emissionFactor.source === 'vehicle_consumption') {
      const fuelSplitted = emissionFactor.fuel.split(' / ');
      sourceDetail =
        fuelSplitted.length === 2
          ? t(`vehicle_fuels.${fuelSplitted[0]}`) + ' / ' + t(`vehicles.${fuelSplitted[1]}`)
          : t(`vehicle_fuels.${emissionFactor.fuel}`);
    }
    if (emissionFactor.source === 'employee' || emissionFactor.source === 'business_travel') {
      const i18nkey = emissionFactor.source === 'employee' ? 'employees' : 'businessTravels';
      sourceDetail = t(`${i18nkey}.${emissionFactor.transport_type}`);
      if (emissionFactor.fuel) {
        sourceDetail += ' ' + t(`${i18nkey}.${emissionFactor.fuel}`);
      }
    }
    if (emissionFactor.source === 'purchase') {
      sourceDetail = t(`purchases.products.${emissionFactor.product_name}`);
    }
    if (emissionFactor.source === 'transport') {
      sourceDetail = emissionFactor.travel_method
        ? t(`shipments.${emissionFactor.travel_method}`)
        : t(`shipments.${emissionFactor.transport_type}`);
    }

    if (emissionFactor.source === 'custom') {
      sourceDetail = emissionFactor.fuel;
    }

    const sourceText = sourceDetail
      ? t(`ghgReport.categories.${emissionFactor.source}`) + ' / ' + sourceDetail
      : t(`ghgReport.categories.${emissionFactor.source}`);

    return (
      <div className='flex-col gap-y-2'>
        <span>{sourceText}</span>
        {emissionFactor.supplier_name && <span>{emissionFactor.supplier_name}</span>}
      </div>
    );
  };

  const cleanUnitName = (unitName: string) => {
    if (unitName.includes('kilometre(km)')) {
      return unitName.replace('kilometre(km)', 'kilometer_(km)');
    }
    if (unitName.includes('kilometre_(km)')) {
      return unitName.replace('kilometre_(km)', 'kilometer_(km)');
    }
    if (unitName.includes('kilowatt_hour(kWh)')) {
      return unitName.replace('kilowatt_hour(kWh)', 'kilowatt_hour_(kwh)');
    }
    return unitName;
  };

  const parseUnitNames = (unitName: string) => {
    if (!unitName) return '';
    const unitNameSplit = unitName.split('/');
    const unitName1 = t(`units.${unitNameSplit[0]}`);
    let unitName2 = unitNameSplit.length > 1 ? t(`units.${unitNameSplit[1]}`) : '';
    if (unitNameSplit[1] === '€') {
      unitName2 = '€';
    }
    let unit = unitName1;
    if (unitName2) {
      unit += '/' + unitName2;
    }
    return unit;
  };

  const renderLocation = (emissionFactor: EmissionFactor) => {
    if (emissionFactor.source === 'business_travel' || emissionFactor.source === 'transport') {
      return emissionFactor.location;
    }
    return getCountryNameByCode(emissionFactor.location, i18n.resolvedLanguage);
  };

  const columns = [
    t('ghgReport.source'),
    t('ghgReport.emissionFactor'),
    t('ghgReport.country'),
    t('ghgReport.ghg'),
    t('ghgReport.unit'),
    t('ghgReport.emissionFactorSource')
  ];

  const emissionFactorsClean = emissionFactors
    .filter((elem) => elem.ef !== undefined && elem.ef !== null)
    .map((emissionFactor) => {
      return {
        ...emissionFactor,
        unit_name: cleanUnitName(emissionFactor.unit_name)
      };
    });

  const rows = getUniqueItemsByProperties(emissionFactorsClean, [
    'ef',
    'fuel',
    'ghg_type',
    'location',
    'source',
    'unit_name'
  ])
    .sort((a: EmissionFactor, b: EmissionFactor) => {
      if (a.source < b.source) {
        return -1;
      } else if (a.source > b.source) {
        return 1;
      } else {
        if (a.fuel < b.fuel) {
          return -1;
        } else if (a.fuel > b.fuel) {
          return 1;
        } else {
          if (a.ghg_type < b.ghg_type) {
            return -1;
          } else if (a.ghg_type > b.ghg_type) {
            return 1;
          } else {
            if (a.location < b.location) {
              return -1;
            } else if (a.location > b.location) {
              return 1;
            } else {
              if (a.unit_name < b.unit_name) {
                return -1;
              } else if (a.unit_name > b.unit_name) {
                return 1;
              } else {
                return 0;
              }
            }
          }
        }
      }
    })
    .map((emissionFactor: EmissionFactor) => {
      return [
        renderSource(emissionFactor),
        formatNumberToDecimalNonZero(emissionFactor.ef),
        renderLocation(emissionFactor),
        t(`general.gasType.${emissionFactor.ghg_type.toUpperCase()}`),
        parseUnitNames(emissionFactor.unit_name),
        getEfSourceTranslation(emissionFactor.ef_source)
      ];
    });
  return <List icon={'/images/icons/thermometer.svg'} columns={columns} rows={rows} />;
};

export default EmissionFactors;
