import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import './styles.scss';

type Props = {
  icon: string;
  label: string;
  placeholder: string;
  value: string;
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: ErrorType;
  changePassword?: boolean;
  disabled?: boolean;
};

const InputPassword = ({
  icon,
  label,
  placeholder,
  value,
  onChangeValue,
  error,
  changePassword,
  disabled = false
}: Props) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(true);
  };

  const handleHidePassword = () => {
    setShowPassword(false);
  };
  return (
    <div className='input'>
      <label className='input-label-font on-light-text-color'>{label}</label>
      <div
        className={`input__password flex ${error ? 'error-border-color' : 'input-border-color'}  ${
          disabled ? 'input-disabled-bg-color' : ''
        }`}>
        {icon && <img src={icon} alt='input-icon' width={18} />}
        {icon && <div className={`divider-line ${error ? 'error-bg-color' : ''}`}></div>}
        <input
          type={showPassword && !value.match(/\*/g) ? 'text' : 'password'}
          className={`input__password__content on-light-text-color input-font ${
            disabled ? 'transparent-bg-color' : ''
          }`}
          value={value}
          placeholder={placeholder}
          onChange={onChangeValue}
        />
        {!showPassword ? (
          <img
            src='/images/icons/eyeCrossed.svg'
            alt='eye-crossed'
            className='eye-icon'
            onClick={handleShowPassword}
          />
        ) : (
          <img
            src='/images/icons/eye.svg'
            alt='eye'
            className='eye-icon'
            onClick={handleHidePassword}
          />
        )}
      </div>
      {changePassword && (
        <Link to={ROUTES.FORGOT_PASSWORD}>
          <span className='change-password-text headline5-font  highlight-text-color'>
            {t('password.changePassword')}
          </span>
        </Link>
      )}
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
};

export default InputPassword;
