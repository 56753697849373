import { BaseEdge, Position, getSmoothStepPath } from 'reactflow';

type Props = {
  id: string;
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  targetPosition: Position;
  markerEnd?: string;
};

export const CustomEdge: React.FC<Props> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  targetPosition,
  sourcePosition,
  markerEnd
}) => {
  const [edgePath] = getSmoothStepPath({
    sourceX: sourceX - 4,
    sourceY,
    targetX: targetX + 4,
    targetY,
    targetPosition,
    sourcePosition
  });

  return <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />;
};
