import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  LCAMaterialMetadata,
  LCAProcessMetadata,
  patchLCAProcessNode
} from '../../../../services/api/lca/lcaPortfolio';

export const usePatchLCAProcessNode = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: patchLCAProcessNode,
    onSuccess: (data, params) => {
      queryClient.setQueryData(
        ['lca-node-metadata', params.acv_id],
        (oldData: { material: Array<LCAMaterialMetadata>; process: Array<LCAProcessMetadata> }) => {
          const oldProcess = oldData.process.find((process) => process.node_id === params.nodeId);

          const newProcess = {
            ...oldProcess,
            node_id: data.node_id,
            name: data.name,
            inputs: data.inputs,
            country: data.country
          };

          return {
            ...oldData,
            process: (JSON.parse(JSON.stringify([...oldData.process])) as Array<LCAProcessMetadata>)
              .filter((process) => process.node_id !== params.nodeId)
              .concat(newProcess)
          };
        }
      );
    }
  });

  return { patchLCAProcessNode: mutateAsync, isLoading: isPending };
};
