import {
  ColumnAPI,
  HookedRecordValue,
  LanguageType,
  OnEntryChange,
  RejectSubmitResult,
  Row
} from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import { questions } from '../../../../../../constants/businessTravelCommutingQuestions';
import useErrors from '../../../../../../customHooks/useErrors';
import { convertBase64 } from '../../../../../../customHooks/useGetBase64';
import useNuvoButton from '../../../../../../customHooks/useNuvoButton';
import { useNuvoCountries } from '../../../../../../customHooks/useNuvoCountries';
import useNuvoFileName from '../../../../../../customHooks/useNuvoFileName';
import { uploadFilePresignedUrl } from '../../../../../../services/api/aws';
import { getPresignedUrlBusinessTravels } from '../../../../../../services/api/businessTravels';
import { postNuvoAnalytics } from '../../../../../../services/api/nuvoAnalytics';
import { basicNuvoStyle } from '../../../../../../styles/nuvo';
import { convertStringToDateBackend } from '../../../../../../utils/convertDates';
import { transformNuvoResultsIntoCsv, validateDateFields } from '../../../../../../utils/nuvo';
import NuvoImporter from '../../../../../ui/nuvoImporter/NuvoImporter';

const InputNuvo = () => {
  const { t, i18n } = useTranslation();
  const ERRORS = useErrors();

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName();
  useNuvoButton({ onClick: handleGetFileName });

  const { countriesNuvo } = useNuvoCountries();

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const columns: ColumnAPI[] = [
    {
      key: 'description(optional)',
      label: t('templates.business_travels.description'),
      columnType: 'string'
    },
    {
      key: 'start_date',
      label: t('templates.business_travels.start_date'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD'
    },
    {
      key: 'end_date',
      label: t('templates.business_travels.end_date'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD'
    },
    {
      key: 'origin_place',
      label: t('templates.business_travels.origin_place'),
      columnType: 'string',
      validations: [
        {
          validate: 'required_without',
          columns: ['distance_km']
        }
      ]
    },
    {
      key: 'origin_postal_code',
      label: t('templates.business_travels.origin_postal_code'),
      columnType: 'string'
    },
    {
      key: 'origin_country',
      label: t('templates.business_travels.origin_country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo.map((country) => ({
        ...country,
        value: country.label
      })),
      validations: [
        {
          validate: 'required_without',
          columns: ['distance_km'],
          errorMessage: ERRORS.NUVO.COUNTRY_REQUIRED_IF_NO_DISTANCE
        }
      ]
    },
    {
      key: 'destination_place',
      label: t('templates.business_travels.destination_place'),
      columnType: 'string',
      validations: [
        {
          validate: 'required_without',
          columns: ['distance_km']
        }
      ]
    },
    {
      key: 'destination_postal_code',
      label: t('templates.business_travels.destination_postal_code'),
      columnType: 'string'
    },
    {
      key: 'destination_country',
      label: t('templates.business_travels.destination_country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo.map((country) => ({
        ...country,
        value: country.label
      })),
      validations: [
        {
          validate: 'required_without',
          columns: ['distance_km'],
          errorMessage: ERRORS.NUVO.COUNTRY_REQUIRED_IF_NO_DISTANCE
        }
      ]
    },
    {
      key: 'distance_km',
      label: t('templates.business_travels.distance_km'),
      columnType: 'float',
      validations: [
        {
          validate: 'required_without_all',
          columns: [
            'origin_place',
            'origin_postal_code',
            'origin_country',
            'destination_place',
            'destination_postal_code',
            'destination_country'
          ]
        }
      ]
    },
    {
      key: 'transport_type',
      label: t('templates.business_travels.transport_type'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.business_travels.travel_method_types.aircraft'),
          value: 'aircraft',
          type: 'string'
        },
        {
          label: t('templates.business_travels.travel_method_types.car_small'),
          value: 'car_small',
          type: 'string'
        },
        {
          label: t('templates.business_travels.travel_method_types.car_medium'),
          value: 'car_medium',
          type: 'string'
        },
        {
          label: t('templates.business_travels.travel_method_types.car_large'),
          value: 'car_large',
          type: 'string'
        },
        {
          label: t('templates.business_travels.travel_method_types.train'),
          value: 'train',
          type: 'string'
        },
        {
          label: t('templates.business_travels.travel_method_types.bus'),
          value: 'bus',
          type: 'string'
        },
        {
          label: t('templates.business_travels.travel_method_types.motorbike'),
          value: 'motorbike',
          type: 'string'
        },
        {
          label: t('templates.business_travels.travel_method_types.trolleybus'),
          value: 'trolleybus',
          type: 'string'
        }
      ],
      isMultiSelect: false,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'fuel_type',
      label: t('templates.business_travels.fuel'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.business_travels.fuel_type.diesel'),
          value: 'diesel',
          type: 'string'
        },
        {
          label: t('templates.business_travels.fuel_type.petrol'),
          value: 'petrol',
          type: 'string'
        },
        {
          label: t('templates.business_travels.fuel_type.electric_not_renewable'),
          value: 'electric_not_renewable',
          type: 'string'
        },
        {
          label: t('templates.business_travels.fuel_type.electric_renewable'),
          value: 'electric_renewable',
          type: 'string'
        },
        {
          label: t('templates.business_travels.fuel_type.do_not_know'),
          value: 'do_not_know',
          type: 'string'
        },
        {
          label: t('templates.business_travels.fuel_type.natural_gas'),
          value: 'natural_gas',
          type: 'string'
        },
        {
          label: t('templates.business_travels.fuel_type.lpg'),
          value: 'lpg',
          type: 'string'
        },
        {
          label: t('templates.business_travels.fuel_type.hybrid'),
          value: 'hybrid',
          type: 'string'
        },
        {
          label: t('templates.business_travels.fuel_type.not_fuel_based'),
          value: 'not_fuel_based',
          type: 'string'
        }
      ],
      isMultiSelect: false,
      validations: [
        {
          validate: 'required_without_values',
          errorMessage: '',
          columnValues: {
            transport_type: ['aircraft']
          }
        }
      ]
    },
    {
      key: 'travel_type',
      label: t('templates.business_travels.round'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.business_travels.round_types.one_way'),
          value: 'one_way',
          type: 'string'
        },
        {
          label: t('templates.business_travels.round_types.round_trip'),
          value: 'return_trip',
          type: 'string'
        }
      ],
      isMultiSelect: false,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'travel_number',
      label: t('templates.business_travels.travel_number'),
      columnType: 'int',
      validations: [
        {
          validate: 'required'
        }
      ]
    }
  ];

  const category = 'business_travels';

  const validateRow = (row: Row) => {
    if (!row || !row.start_date || !row.end_date) return;

    const errors = {} as any;

    if (convertStringToDateBackend(row.start_date as string) > new Date()) {
      errors.start_date = {
        value: row.start_date,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.START_DATE_IN_FUTURE
          }
        ]
      };
    }

    if (convertStringToDateBackend(row.end_date as string) > new Date()) {
      errors.end_date = {
        value: row.end_date,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.END_DATE_IN_FUTURE
          }
        ]
      };
    }

    if (
      convertStringToDateBackend(row.start_date as string) >
      convertStringToDateBackend(row.end_date as string)
    )
      errors.end_date = {
        value: row.end_date,
        info: [
          {
            message: ERRORS.NUVO.END_DATE_BEFORE_START_DATE,
            level: 'error'
          }
        ]
      };

    const minYearErrors = validateDateFields(['start_date', 'end_date'])(row);

    for (const key in minYearErrors) {
      errors[key] = minYearErrors[key];
    }

    if (!row.travel_type || row.travel_type === '') {
      errors.travel_type = {
        value: row.travel_type,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.VALUE_REQUIRED
          }
        ]
      };
    }

    // check if transport type and fuel combination is correct
    const foundQuestion = questions.children.find(
      (question: { answer: string }) => question.answer === row.transport_type
    );

    const foundFuel = foundQuestion?.children?.find(
      (fuel: { answer: any }) => fuel.answer === row.fuel_type
    );

    if (!foundFuel && foundQuestion?.children) {
      errors.fuel_type = {
        value: row.fuel_type,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.FUEL_TYPE_NOT_ALLOWED
          }
        ]
      };
    }

    return errors;
  };

  const onEntryChange: OnEntryChange = (rows) => {
    return rows
      .filter((row) => Object.keys(validateRow(row.data) ?? {}).length > 0)
      .map((row) => {
        return {
          rowIndex: row.rowIndex,
          data: {
            ...row.data,
            ...validateRow(row.data)
          }
        };
      });
  };

  return (
    <NuvoImporter
      btnI18nKey='businessTravels.uploadAuto'
      settings={{
        multipleFileUpload: true,
        language: i18n.resolvedLanguage as LanguageType,
        style: basicNuvoStyle,
        automaticHeaderDetection: true,
        maxEntries: 700_000,
        enableExamples: true,
        identifier: 'business_travels_template_Dcycle', // Template file name
        columns
      }}
      onCancel={handleExit}
      onEntryInit={validateRow}
      onEntryChange={onEntryChange}
      columnHooks={{
        // travel_type: (values: HookedRecordValue[]) =>
        //   values.map(([item, index]) => [
        //     {
        //       value: item || 'one_way'
        //     },
        //     index
        //   ]),
        travel_number: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 1
            },
            index
          ]),
        transport_type: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 'do_not_know'
            },
            index
          ]),
        fuel_type: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 'do_not_know'
            },
            index
          ])
      }}
      onResults={async (results, errors, complete) => {
        // cannot submit file with errors
        if (errors.length > 0) return complete(nuvoErrorFounded);

        if (results.length <= 0) return complete(nuvoError);

        // transform results into csv string
        const content = transformNuvoResultsIntoCsv(results);

        const finalFileName = `${fileName || category}.csv`;

        // transform content to File
        const file = new File([content], finalFileName, { type: 'text/csv' });

        // transform file to base64
        const base64file = await convertBase64(file);

        // error converting file
        if (!base64file) return complete(nuvoError);

        // get presinged url
        const data = await getPresignedUrlBusinessTravels({
          data: base64file,
          file_name: finalFileName
        });

        // error uploading file
        if (data?.response?.status >= 400) return complete(nuvoError);

        // upload file to presigned url
        const response = await uploadFilePresignedUrl(file, data?.upload_url);

        // error uploading file
        if (!response) return complete(nuvoError);

        // analytics
        await postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category: category
        });

        complete();
      }}
    />
  );
};

export default InputNuvo;
