import { SIZE_PAGINATION_BIG } from '../../constants';
import apiFetch from '../../utils/apiFetch';

type GetListProps = {
  page: number;
  size: number;
};

export const getListCustomEFGroup = async ({ page, size = SIZE_PAGINATION_BIG }: GetListProps) => {
  try {
    const response = await apiFetch(
      'GET',
      '/custom_emission_groups', //  : check url
      {},
      {},
      {
        page,
        size
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

type GetProps = {
  id: string;
};

export const getCustomEFGroup = async ({ id }: GetProps) => {
  try {
    const response = await apiFetch('GET', `/custom_emission_groups/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

type PostProps = {
  data: ICustomEFGroupBack;
};

export const postCustomEFGroup = async ({ data }: PostProps) => {
  try {
    const response = await apiFetch('POST', `/custom_emission_groups`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

type PatchProps = {
  id: string;
  data: ICustomEFGroupBack;
};

export const patchCustomEFGroup = async ({ id, data }: PatchProps) => {
  try {
    const response = await apiFetch('PATCH', `/custom_emission_groups/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteCustomEFGroup = async (id: string) => {
  try {
    const response = await apiFetch('DELETE', `/custom_emission_groups/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCustomEFGroupUnits = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/custom_emission_groups/units/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
