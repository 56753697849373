import { HTMLAttributes } from 'react';
import LoaderTables from '../loaders/loaderTables/LoaderTables';
import './styles.scss';

type Props = {
  listView: boolean;
  title?: string;
  description?: string | React.ReactElement;
  loading: boolean;
  buttons?: React.ReactElement;
  children?: React.ReactNode;
  information?: React.ReactNode;
  showInformation?: boolean;
};
function CardList({
  listView,
  title,
  description,
  loading,
  buttons,
  information = null,
  showInformation = true,
  children
}: Props) {
  return (
    <div className={`card-list ${listView ? 'list' : ''}`}>
      {showInformation && (
        <div className='card'>
          <div
            style={{
              alignSelf: 'flex-start'
            }}>
            {information}
          </div>
          <div className='create-new-card card-border-color '>
            {title && <h1 className='headline4-font'>{title}</h1>}
            {description && <p className='subtitle3-font'>{description}</p>}
            {buttons && <div className='button-wrapper'>{buttons}</div>}
          </div>
        </div>
      )}
      {loading && <LoaderTables mode='list' />}
      {!loading && children}
    </div>
  );
}

type InformationProps = HTMLAttributes<HTMLDivElement>;

const Information = ({ ...rest }: InformationProps) => {
  return <div className='text-tag body3-font tag-bg-text-color tooltip-card-measure' {...rest} />;
};

CardList.Information = Information;

export default CardList;
