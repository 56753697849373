import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { FilterDatesContext } from '../../../../../context/filterDatesContext';
import { getOrganizationESGStatus } from '../../../../../services/api/esg';
import { EsgCategoryCompleteness } from '../../../../../types/entities/esgMetrics';
import { formatNumberToDecimalNonZero } from '../../../../../utils/numberToDecimal';

const useData = () => {
  const { startDate, endDate } = useContext(FilterDatesContext);

  const [organizationESGStatus, setOrganizationESGStatus] = useState<EsgCategoryCompleteness[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    const startDateParsed = moment(startDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
    const endDateParsed = moment(endDate, 'DD/MM/YYYY').format('YYYY/MM/DD');

    setLoading(true);
    const data = await getOrganizationESGStatus(startDateParsed, endDateParsed, [
      {
        field: 'data_source',
        type: 'eq',
        value: 'F'
      }
    ]);
    setOrganizationESGStatus(data);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) return;

    fetchData();
  }, [startDate, endDate]);

  const environmental = organizationESGStatus?.find((item) => item?.category === 'environmental');
  const social = organizationESGStatus?.find((item) => item?.category === 'social');
  const governance = organizationESGStatus?.find((item) => item?.category === 'governance');

  const environmentalCompleteness = environmental
    ? (100 * environmental?.completed) / environmental?.total
    : 0;

  const socialCompleteness = social ? (100 * social?.completed) / social.total : 0;

  const governanceCompleteness = governance ? (100 * governance?.completed) / governance.total : 0;

  const dataEnvironmental = [
    { title: 'environmental', value: environmentalCompleteness, color: 'url(#gradient1)' }
  ];

  const dataSocial = [{ title: 'social', value: socialCompleteness, color: 'url(#gradient1)' }];

  const dataGovernance = [
    { title: 'governance', value: governanceCompleteness, color: 'url(#gradient1)' }
  ];

  const allData = [dataEnvironmental, dataSocial, dataGovernance];

  const dataMetrics = [
    {
      name: 'environmentalMetrics',
      progress: environmental
        ? `${environmental?.completed}/${environmental?.total} datapoints`
        : '0 datapoints'
    },
    {
      name: 'socialMetrics',
      progress: social ? `${social?.completed}/${social?.total} datapoints` : '0 datapoints'
    },
    {
      name: 'governanceMetrics',
      progress: governance
        ? `${governance?.completed}/${governance?.total} datapoints`
        : '0 datapoints'
    }
  ];

  const progress = [
    `${formatNumberToDecimalNonZero(environmentalCompleteness, 1)}%`,
    `${formatNumberToDecimalNonZero(socialCompleteness, 1)}%`,
    `${formatNumberToDecimalNonZero(governanceCompleteness, 1)}%`
  ];

  return {
    allData,
    dataMetrics,
    progress
  };
};

export default useData;
