import moment from 'moment';
import { EmissionFactorType } from '../../types/entities/emissionFactor';
import apiFetch from '../../utils/apiFetch';

export const getGroups = async (organization_id: string, returnRequest = false) => {
  try {
    const request = apiFetch(
      'GET',
      `/custom_emission_groups/organization_emission_data/${organization_id}`
    );
    return returnRequest ? request : (await request).data;
  } catch (error) {
    return null;
  }
};

//GROUPS
type PostGroupCustomizeType = {
  category: string;
  name: string;
  description: string;
  ghg_type: number;
  organization_id: string;
  custom_emission_factors: EmissionFactorType[];
};

export const postGroupCustomize = async ({
  category,
  name,
  description,
  ghg_type = 0,
  organization_id,
  custom_emission_factors
}: PostGroupCustomizeType) => {
  try {
    const custom_emission_factors_data = custom_emission_factors.map((element) => ({
      ...element,
      factor_start_date: moment(element.factor_start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      factor_end_date: moment(element.factor_end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }));
    const response = await apiFetch('POST', `/custom_emission_groups/associated_data`, {
      category,
      name,
      description,
      ghg_type,
      organization_id,
      custom_emission_factors: custom_emission_factors_data
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getEmissionFactorGroup = async (organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/custom_emission_groups/organization_emission_data/${organization_id}`
    );
    return response.data.emission_groups;
  } catch (error) {
    return error;
  }
};

export const deleteGroupEmissionFactor = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/custom_emission_groups/${id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data.emission_groups;
  } catch (error) {
    return error;
  }
};

// EMISSION FACTORS

type BodyPatchGroup = {
  name: string;
  description: string;
};

export const patchGroupEmissionFactor = async (
  id: string,
  organization_id: string,
  body: BodyPatchGroup
) => {
  try {
    const response = await apiFetch('PATCH', `/custom_emission_groups/${id}`, body, {
      'x-organization-id': organization_id
    });
    return response.data.emission_groups;
  } catch (error) {
    return error;
  }
};

export const deleteEmissionFactor = async (
  id: string,
  custom_emission_group_id: string,
  returnRequest?: boolean
) => {
  try {
    const request = await apiFetch('DELETE', `/custom_emission_factors/${id}`, null, {
      'x-custom-emission-group-id': custom_emission_group_id
    });
    return returnRequest ? request : (await request).data.emission_groups;
  } catch (error) {
    return error;
  }
};

type PostEmissionFactorType = {
  custom_emission_group_id: string;
  organization_id: string;
  custom_emission_factors: EmissionFactorType[];
  returnRequest?: boolean;
};

export const postEmissionFactor = async ({
  custom_emission_group_id,
  organization_id,
  custom_emission_factors,
  returnRequest = false
}: PostEmissionFactorType) => {
  try {
    const custom_emission_factors_data = custom_emission_factors.map((factor) => ({
      ...factor,
      factor_start_date: moment(factor.factor_start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      factor_end_date: moment(factor.factor_end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }));
    const request = apiFetch('POST', `/custom_emission_factors`, {
      custom_emission_group_id,
      organization_id,
      custom_emission_factors: custom_emission_factors_data
    });
    return returnRequest ? request : (await request).data;
  } catch (error) {
    return error;
  }
};
