import { useContext, useRef, useState } from 'react';
import { ROUTES } from '../../../../constants/routes';
import { UserContext } from '../../../../context/userContext';
import NameIcon from '../../../ui/nameIcon/NameIcon';
import UserModal from './UserModal';

const UserButton = () => {
  const user = useContext(UserContext);

  const userRef = useRef<HTMLDivElement>(null);
  const [showUserModal, setShowUserModal] = useState(false);

  const handleOnCloseProfile = () => setShowUserModal(false);
  const handleOnClickProfile = () => setShowUserModal(!showUserModal);

  return (
    <div className='header-profile' ref={userRef}>
      <div
        className='flex pointer'
        style={{ gap: '0.5rem', alignItems: 'center' }}
        onClick={handleOnClickProfile}>
        {user?.profile_img_url && (
          <img
            src={user.profile_img_url}
            className='round profile-img'
            alt='profile'
            onClick={handleOnClickProfile}
          />
        )}
        {user?.first_name && !user.profile_img_url && (
          <NameIcon
            name={user.first_name}
            lastName={user.last_name || ''}
            size='medium'
            selected={
              showUserModal ||
              location.pathname === ROUTES.PROFILE ||
              location.pathname === ROUTES.MEMBERS
            }
          />
        )}
        <span className='body1-bold-font'>{`${user?.first_name} ${
          user?.last_name ? user?.last_name[0] : ''
        }`}</span>
      </div>
      {showUserModal && <UserModal handleOnClickProfile={handleOnCloseProfile} userRef={userRef} />}
    </div>
  );
};

export default UserButton;
