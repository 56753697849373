import i18next from 'i18next';
import moment from 'moment';
import { EntryChangeResult, FieldValue, ResultValues, Row, SettingsAPI } from 'nuvo-react';
import {
  highlight_color,
  secondary_bg_color,
  secondary_border_color,
  secondary_color
} from '../styles/colors';

export const transformNuvoResultsIntoCsv = (results: ResultValues) => {
  const headers = Object.keys(results[0]).map((key) => key);
  const body: string[][] = [];

  results.forEach((row, rowIndex) => {
    headers.forEach((key) => {
      const value = String(row[key] ?? ' ');
      if (body[rowIndex]) {
        body[rowIndex].push(`"${value}"`);
      } else {
        body[rowIndex] = [`"${value}"`];
      }
    });
  });

  const content = [
    headers.join(','),
    body
      .map((row) => {
        return row.join(',');
      })
      .join('\r\n')
  ].join('\r\n');

  return content;
};

export const treatNuvoString = (value: string) => {
  if (value.includes('"')) {
    return value.replaceAll('"', "'");
  }

  return value;
};

export const treatLine = <T extends object>(obj: T) => {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    const value = obj[key];

    if (typeof value === 'string') {
      newObj[key] = treatNuvoString(value);
      continue;
    }

    newObj[key] = value;
  }

  return newObj;
};

const MIN_YEAR = 1970;

const t = i18next?.t;

const isValidDate = (value: FieldValue) =>
  typeof value === 'string' && moment(value, 'YYYY-MM-DD').year() < MIN_YEAR;

export const validateDateFields = (keys: string[]) => {
  return (row: Row) => {
    const invalidKeys = keys.filter((key) => isValidDate(row[key]));

    const response = invalidKeys.reduce((acc, key) => {
      acc[key] = {
        value: row[key],
        info: [
          {
            level: 'error',
            message: t('nuvo.errors.minYear')
          }
        ]
      };

      return acc;
    }, {} as EntryChangeResult['data']);

    return response;
  };
};

type INuvoStyle = {
  secondary: SettingsAPI['style'];
  primary: SettingsAPI['style'];
  link: SettingsAPI['style'];
};
export const NUVO_STYLE: INuvoStyle = {
  primary: undefined,
  secondary: {
    buttons: {
      import: {
        backgroundColor: secondary_bg_color,
        color: secondary_color,
        border: `1px solid ${secondary_border_color}`,
        borderRadius: '8px'
      }
    }
  },
  link: {
    buttons: {
      import: {
        backgroundColor: '#00FF0000',
        color: highlight_color,
        width: '10%'
      }
    }
  }
};
